import React from 'react'
import '../../App.css';

import ComponentVeinte from '../../assets/icons/car/Component 20.png';
import ComponentVeintiUno from '../../assets/icons/car/Component 21.png';
import ComponentVeintiDos from '../../assets/icons/car/Component 22.png';

function ServiceCarTresMobile() {
  return (
    <div  className='infoServiceCar'>
    <div className='container-service-car'>
      <div className='containerService'>
        <div className='imgBordIcon2'>
          <img src={ComponentVeinte}   className='imgIconCar'/>
        </div>        
        <h1 className='TitleCard'>Emergency charging</h1>
        <text className='TextCard'>IIf you find yourself with a depleted <br/>battery, we'll dispatch a charging  <br/> truck to your location.</text>
      </div>
      <div className='containerService'>
        <div className='imgBordIcon2'>
          <img src={ComponentVeintiUno}   className='imgIconCar'/>
        </div> 
        <h1 className='TitleCard'>EV/Hybrid discounts</h1>
        <text className='TextCard'>An additional discount as a token  <br/> of appreciation for eco-conscious  <br/> drivers.</text>
      </div>
      <div className='containerService'>
        <div className='imgBordIcon2'>
          <img src={ComponentVeintiDos}   className='imgIconCar'/>
        </div>
        <h1 className='TitleCard'>Charger coverage</h1>
        <text className='TextCard'>If anything occurs to your wall or  <br/> portable charger, we'll assist in its  <br/>replacement.</text>
      </div>
    </div>
    </div>
  )
}

export default ServiceCarTresMobile
