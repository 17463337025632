import React from 'react'
import '../../App.css';


import ComponentVeintiTres from '../../assets/icons/pet/Diagnostics.png';
import ComponentVeintiCuatro from '../../assets/icons/pet/Procedures.png';
import ComponentVeintiCinco from '../../assets/icons/pet/Medication.png';


function ServicePet() {
  return (
    <>
    <div className='InfoCard'>
      <div className='containerCard'>
        <div className='imgBordIcon'>
          <img src={ComponentVeintiTres} className='imgIcon'/>
        </div>        
        <h1 className='TitleCard'>DIAGNOSTICS</h1>
        <text className='TextCard'>Blood tests, urinalysis, x-rays,<br/> MRIs, labwork, CT scans, and <br/>ultrasounds</text>
      </div>
      <div className='containerCard'>
        <div className='imgBordIcon'>
          <img src={ComponentVeintiCuatro} className='imgIcon'/>
        </div>        
        <h1 className='TitleCard'>PROCEDURES</h1>
        <text className='TextCard'>Outpatient, specialty and <br/> emergency care, hospitalization,<br/> and surgery</text>
      </div>
      <div className='containerCard'>
        <div className='imgBordIcon'>
          <img src={ComponentVeintiCinco} className='imgIcon'/>
        </div>        
        <h1 className='TitleCard'>MEDICATION</h1>
        <text className='TextCard3'>Injections and prescription meds</text>
      </div>
    </div>
    </>
 
  )
}

export default ServicePet
