import React, {useState, useEffect} from 'react'
import "../../App.css"
import '@fontsource/open-sans' 
import styled from 'styled-components'
import BurguerButton from './BurguerButton'
import Logo from '../../assets/log/LogoFigNegro.png'


function Navbar() {
  const [clicked, setClicked] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false); // Nuevo estado para controlar la vista
  const [isTabletView, setIsTabletView] = useState(false);

  const handleClick = () => {
    setClicked(!clicked);
  };

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 40);
    };
    
    const handleResize = () => {
      const width = window.innerWidth;
      setIsMobileView(width <= 600);
      setIsTabletView(width > 600 && width <= 1023);
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);

    handleResize(); // Llamada inicial para establecer el estado correcto

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
  <NavContainer className={scrolled ? 'scrolled' : ''}>
    <NavLink  active={clicked}  className={`links ${clicked ? 'active' : ''}`}>
      <a  href="/renters">Renters</a>
      <a  href="/homeowners">Homeowners</a>
      <a  href="/car">Car</a>
      <a href="/life">Life</a>
      <a href="/pet">Pet</a>
    </NavLink>      
      {scrolled && !isMobileView && !isTabletView && (
        <a href="/" className='logo'>
          <LogoImage src={Logo} className={scrolled ? 'scrolled' : ''} />
        </a> 
      )}
      {!scrolled && !isMobileView && !isTabletView && (
        <a href='/' className='logo'>
          <LogoImage src={Logo}  style={{  position: 'absolute'}} />
        </a>
      )}
    {(isMobileView || isTabletView) && (
        <div className="burguer">
          <BurguerButton clicked={clicked} handleClick={handleClick} />
        </div>
      )}
    <BgDiv active={clicked}></BgDiv> 
    {isMobileView && (
        <a href="/" className='logo'>
          <LogoImage src={Logo} className="mobile" />
        </a>
      )}
      {isTabletView && !isMobileView && (
        <a href="/" className='logo'>
          <LogoImage src={Logo} className="tablet" />
        </a>
      )}
    <br/> 
    {isMobileView ? (
      <p style={{fontSize:'15px',width:'17%'}}>Account</p>
    ) : (
      <p style={{fontSize:'15px'}}>Account</p>
    )}
  </NavContainer>
  )
}

export default Navbar;


const NavContainer = styled.nav`
position: sticky;
top: 0;
z-index: 100;
background-color: white;
display:flex;

padding: 0 20px 0 20px;
align-items: center;
justify-content: space-between;

&.scrolled {
  background-color: #ffff;
  border-bottom: 1px solid #c4c4c4;
}
@media (min-width: 1024px) {
  /* Estilos para dispositivos móviles */
}
  h2 {
    color: black;
    font-weight: 400;
    font-size: 1.8vw;
    margin: 0px 0px;
    @media (min-width: 1001px) {
      font-size: 3vw; /* Cambiar el tamaño de fuente para dispositivos móviles */
    }
  }
  a{
    margin: 0px 0px;
    text-decoration: none;
    font-family: "Open Sans";
  }
  .links {
    position: absolute;
    left: -1000px;
    right: 0;
        width: 24%;
    a {
          margin-right: 1.5rem;
      color: black;
      font-size:  1vw;
      display: flex;
      font-family: "Open Sans";
    }
    @media (min-width: 1024px) {
      position: initial;
      margin: 0;
      a {
        font-size: 15px;
        color: black;
        display: inline;
        font-family: "Open Sans";
      }
      a:hover {
        color: #6937F4;
        border-bottom: 1px solid #6937F4;
      }
    }
  }

  .links.active{
    width: 45%;
    margin-top: 0; 
    top: 20%;
    left: 0;
    height: 1000px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    line-height: 40px;
        a{
      font-size:  20px;
      color: white;
      font-family: "Open Sans";
      margin-top: 2%
    }
    
  }
  .burguer{
    @media(min-width: 1024px){
      display: none;
    }
  }

  &.active {
    // Estilos para el navbar cuando el botón de hamburguesa está activo
    // Puedes agregar estilos específicos cuando el menú está abierto
  }
`
const LogoImage = styled.img`
  width: 160px;
  height: 80px;
  margin-top: 10px;

  &.scrolled {
    width: 120px;
    height: 60px;
  }

  &.mobile {
    width: 110px;
    height: 55px;
  }
  &.tablet {
    width: 110px;
    height: 55px;
    margin-left: 130%;
  }
`;

const BgDiv = styled.div`
  position: absolute;
  top: -1000px;
  left: -1000px;
  height: 100%;
  z-index: -1;
  transition: right 0.3s ease;


  &.active{
    top: 0;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    height: 100%;
  }
`
const NavLink = styled.a`
  color: white;
  background-color: #6937f4;
  padding: 0.5rem 1rem;
  margin: 1rem 1rem;
  text-decoration: none;
  margin-right: 1rem;
  font-family: "Open Sans";
  font-size: 1vw;
  transition: left 0.5s ease;


  @media(min-width: 1024px) {
    font-size: 1.2vw; 
    background-color: transparent;
    color: black;
    margin: 0 1rem; 
    padding: 0; 
  }
`;
