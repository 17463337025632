import React,{useState} from 'react'
import '../../App.css'
import FlechaAbajo from '../../assets/img/flechaAbajo.png'
import FlechaArriba from '../../assets/img/flechaArriba.png'

function QuestionsPet() {
  const questions = [
    {"Question":"What does pet insurance cover?","Answer":"A fundamental accident and illness policy can provide coverage for diagnostics, procedures, medications, and hospitalizations for new accidents (such as broken bones) and illnesses. This includes hereditary conditions like hip dysplasia, chronic conditions, infestations, and infections."},
    {"Question":"How does pet insurance work?","Answer":"FIG Pet insurance provides coverage for new accidents or illnesses your pet may experience, saving you from unexpected vet expenses. Additionally, FIG Pet offers various preventive packages and add-ons, allowing you to tailor your policy to meet your pet's specific needs."},
    {"Question":"Why should I get pet insurance?","Answer":"Unexpected veterinary expenses can accumulate, forcing you to choose between your pet's health and financial stability. Pet insurance provides assurance that if an unforeseen event occurs, you won't have to deplete your savings."},
    {"Question":"How do I add another pet?","Answer":"Every pet requires its own policy, and you'll need to establish them individually. Once you've obtained a pet insurance policy for your initial pet, simply tap the + symbol on the FIG website to receive a new quote for your other dog or cat. Additionally, you can unlock a 5% multi-pet discount."},
    {"Question":"What are waiting periods?","Answer":"Similar to other pet insurers, we have waiting periods for specific coverages: 2 days for accidents, 14 days for illnesses, and 6 months for cruciate ligament events. However, if you chose our preventative package, you can access its benefits the day after purchasing the policy!"},
    {"Question":"Do you need my pet’s medical records?","Answer":"We aim to understand your pet for an optimal experience. After securing your insurance policy, we'll request your pet's medical records, providing crucial insights into any pre-existing conditions and expediting the claim review process."},
    {"Question":"What are pre-existing conditions and are they covered?","Answer":"Any condition your pet exhibits signs of before the waiting periods of the policy are concluded is deemed pre-existing. However, we provide coverage for accidents and new illnesses as part of our fundamental policy."},
    {"Question":"How do pet insurance claims work?","Answer":"FIG Pet operates on a reimbursement model. You cover the vet bill initially, file a claim on the FIG website right away, and upon approval, we'll reimburse your bank account, considering your deductible and co-insurance."},
    {"Question":"Can I go to any vet?","Answer":"You have the freedom to choose any vet nationwide. They just need to be licensed in the state where they practice."},
    {"Question":"Does FIG offer pet insurance near me?","Answer":"FIG’s pet insurance is now offered in 37 states, along with Washington, D.C. Explore the availability of FIG Pet and our other coverages."},
    {"Question":"Can I change my policy later?","Answer":"You can request coverage modifications in the initial 14 days of your policy and during its annual renewal. Our underwriting team will inform you once the changes are approved after submission."},
    {"Question":"Can I cancel my policy at any time?","Answer":"Certainly! You have the flexibility to cancel your policy at any time using the FIG website. Please note that our waiting periods will reset, and any events or diagnoses during your initial policy will be treated as pre-existing conditions in a future policy."},
]
   
const [expandedQuestions, setExpandedQuestions] = useState([]);

const handleToggle = (index) => {
  setExpandedQuestions(prevState =>
    prevState.includes(index)
      ? prevState.filter(i => i !== index)
      : [...prevState, index]
  );
};

return (
<div className='container'>
      {questions.map((question, index) => (
        <div key={index}>
          <div className='header' onClick={() => handleToggle(index)}>
            <span className='title'>{question.Question}</span>
            <img
              src={expandedQuestions.includes(index) ? FlechaAbajo : FlechaArriba}
              alt={expandedQuestions.includes(index) ? 'flecha hacia abajo' : 'flecha hacia arriba'}
              className='icon'
            />
          </div>
          {expandedQuestions.includes(index) && (
            <div className={`content ${expandedQuestions.includes(index) ? 'expanded' : ''}`}>
              <p className='text'>{question.Answer}</p>
            </div>
          )}
        </div>
      ))}
    </div>
  )
}

export default QuestionsPet
