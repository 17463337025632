import React from 'react';
import './Animated/animation.css';
import TestimonialsMobile from './TestimonialsMobile';


import Image1 from '../assets/icons/carousel/Image1.png';
import Image2 from '../assets/icons/carousel/Image2.png';
import Image3 from '../assets/icons/carousel/Image3.png'

function CarouselMobile() {
  const items = [
    {"icon":Image1,"title": "Brent Goldstein", "subtitle": "@brentgold", "description": "@FIG_LLC was the simplest and easiest insurance I’ve ever purchased, “old” insurance companies need to step into the current century!!! 👏 👏"},
    {"icon":Image2,"title":  "Rahim Karimi", "subtitle": "@rahimk", "description": "@FIG_LLC I just bought home insurance from you and I’m pretty sure it was easier than ordering pizza. Awesome job on the experience."},
    {"icon":Image3,"title":  "Herbert Siojo", "subtitle": "@Sherbert", "description": "WOW - what an amazing company. Check out @FIG_LLC if you need homeowner or rental insurance. Have been loving the process so far."},

  ];

  return (
    <div className='custom-container-mobile'>
      <div className=' animated-card'>
        {items.map((item, index) => (
          <TestimonialsMobile key={index} item={item} />
        ))}
      </div>
    </div>
  );
}

export default CarouselMobile;
