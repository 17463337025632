import React from 'react';
import { BrowserRouter as Router, Route  } from 'react-router-dom';

import Home from './features/Home';
import Renters from './features/Render';
import Life from './features/Life';
import Homeowner from './features/Homeowner';
import Car from './features/Car'
import Pet from './features/Pet';
import PrivacyPolicy from './components/Modal/PrivacyPolicy'


const App = () => {
  return (
    <Router className='app'>
      <Route exact path="/" component={Home} />
      <Route path="/renters" component={Renters} />
      <Route path="/life" component={Life} />
      <Route path="/homeowners" component={Homeowner} />
      <Route path="/car" component={Car} />
      <Route path="/pet" component={Pet} />
      <Route path="/privacy-policy" component={PrivacyPolicy}/>
    </Router>
  );
}

export default App;
