import React from 'react';
import '../../App.css';

import ComponentDos from '../../assets/icons/homeOwners/iconoladron_1.png';
import ComponentCuatro from '../../assets/icons/renders/Comp4.png';
import ComponentCinco from '../../assets/icons/renders/Comp5.png';
import ComponentSeis from '../../assets/icons/homeOwners/Comp6.png';
import ComponentSiete from '../../assets/icons/homeOwners/Comp7.png';
import ComponentOcho from '../../assets/icons/homeOwners/Comp8.png';


function ServiceHome() {
  return (
    <>
    <div className='InfoCardHomeowner'>
      <div className='containerCard'>
        <div className='imgBordIcon'>
          <img src={ComponentSeis}  className='imgIcon13'/>
        </div>
        <h1 className='TitleCard'>If your residence <br/>suffers damage</h1>
        <text className='TextCard2'>We'll handle the expenses for <br/> rebuilding your home.</text>
      </div>
      <div className='containerCard'>
        <div className='imgBordIcon'>
          <img src={ComponentSiete}  className='imgIcon13'/>
        </div>
        <h1 className='TitleCard'>If a tree collapses <br/> onto your shed</h1>
        <text className='TextCard'>We'll assist in covering the <br/> expenses for repairing <br/> structures on your property,  <br/> such as sheds and garages.</text>
      </div>
      <div className='containerCard'>
        <div className='imgBordIcon'>
          <img src={ComponentOcho}  className='imgIcon14'/>
        </div>
        <h1 className='TitleCard'>If your residence is <br/> unfit for living</h1>
        <text className='TextCard2'>We'll take care of costs <br/>until you recover.</text>
      </div>
    </div>
    <div className='InfoCardHomeowner'>
      <div className='containerCard'>
        <div className='imgBordIcon'>
          <img src={ComponentDos}  className='imgIcon6_2'/>
        </div>
        <h1 className='TitleCard'>If your belongings  <br/> are stolen</h1>
        <text className='TextCard4'>We'll assist in covering <br/> replacement costs, even if it <br/> occurs while you’re outside <br/> your home.</text>
      </div>
      <div className='containerCard'>
        <div className='imgBordIcon'>
          <img src={ComponentCuatro}  className='imgIcon7_1'/>
        </div>        
        <h1 className='TitleCard'>If you face a <br/> liability lawsuit</h1>
        <text className='TextCard4'>You'll require legal <br/> representation, and we'll <br/> assist in covering those <br/>expenses.</text>
      </div>
      <div className='containerCard'>
        <div className='imgBordIcon'>
          <img src={ComponentCinco}  className='imgIcon6_2'/>
        </div>        
        <h1 className='TitleCard'>If a visitor sustains  <br/>an injury</h1>
        <text className='TextCard4'>You might end up <br/> responsible for their medical <br/> expenses, but we'll assist in <br/> covering those costs.</text>
      </div>
    </div>
    </>
  )
}

export default ServiceHome
