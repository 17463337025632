import React from 'react'
import '../../App.css';


import ComponentUno from '../../assets/icons/renders/Comp1.png';
import ComponentDos from '../../assets/icons/renders/Comp2.png';
import ComponentTres from '../../assets/icons/renders/Comp3.png';
import ComponentCuatro from '../../assets/icons/renders/Comp4.png';
import ComponentCinco from '../../assets/icons/renders/Comp5.png';


function ServiceRenters() {
  return (
    <>
    <div className='InfoCard'>
      <div className='containerCard'>
        <div className='imgBordIcon'>
          <img src={ComponentUno} className='imgIcon'/>
        </div>
        <h1 className='TitleCard'>If someone  <br/>steals your bicycle</h1>
        <text className='TextCard'>Coverage for crime and vandalism <br/> reimburses you for your <br/> belongings in the event of damage <br/> or theft.</text>
      </div>
      <div className='containerCard' >
        <div className='imgBordIcon'>
          <img src={ComponentDos} className='imgIcon11'/>
        </div>
        <h1 className='TitleCard'>If water leakage <br/>damages your things</h1>
        <text className='TextCard'>Water damage coverage <br/>safeguards you in case your <br/> electronics get wet or your pipes <br/>create a mess.</text>
      </div>
      <div className='containerCard'>
        <div className='imgBordIcon'>
          <img src={ComponentTres} className='imgIcon3'/>
        </div>
        <h1 className='TitleCard'>If your belongings are <br/> destroyed by fire</h1>
        <text className='TextCardRenter4'>Fire and smoke coverage ensures <br/> you're protected when things heat<br/> up</text>
      </div>
    </div>
    <text className='SubTitleCard'>Also, shield yourself from damages caused by someone else's accidents occurring in your home</text> 
    <div className='InfoCardDos'>
      <div className='containerCardDos'>
        <div className='imgBordIcon'>
          <img src={ComponentCuatro} className='imgIcon4'/>
        </div>
        <h1 className='TitleCard'>If someone sues you <br/> for compensation</h1>
        <text className='TextCardRenter4'>Liability coverage guards against <br/> lawsuits arising from accidents in <br/>your home. We'll provide legal <br/> representation and cover <br/>associated expenses</text>
      </div>
      <div className='containerCardDos'>
        <div className='imgBordIcon'>
          <img src={ComponentCinco} className='imgIcon'/>
        </div>
        <h1 className='TitleCard'>If someone is injured <br/> in your house</h1>
        <text className='TextCard2'>We'll assist in covering medical <br/> expenses if someone has an <br/> accident or sustains an injury while <br/> at your home.</text>
      </div>
    </div>
    </>
 
  )
}

export default ServiceRenters
