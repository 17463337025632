import React from 'react'

const Descriptionhom = ({item}) => {
  console.log("items",item)
  const isCar = item.Servicie === "Car";
  const isPet = item.Servicie === "Pet";
  const isLife = item.Servicie === "Term life"

  return (
    <div style={styles.BoxContainer}>
      <img src={item.Image} width={'50%'} height={'50%'} style={{marginTop:'5%'}}/>
      <br/>
      <text style={styles.title}>{item.Servicie}</text>
      <div style={{...styles.descrip,...(isPet && styles.PetContainer),...(isLife && styles.LifeContainer)} }>
        <text style={styles.text}>{item.Description}</text>
      </div>  
      <div style={styles.button}>
        <a href={item.Link}>
        <button style={styles.ButtonBox}>
            <p style={styles.TextButtonBox}>CONTACT US</p>
          </button>
        </a>
        <text style={styles.subtext} >{item.Price}</text>
      </div>
    </div>
  )
}

const styles = {
  BoxContainer:{
    textAlign: 'center',
    marginTop:'2%',
    paddingBottom:'3%',
    fontFamily: "Open Sans",
    backgroundColor: 'white',
    width: '18%',
    height:'100%',
    borderRadius:'15px',
    boxShadow: '0.5vw 1vw 1vw 0.2vw  #D9D9D9',
    display:'inline-block',
    marginRight:'2%'
  },
  title:{
    fontSize: '1.2vw',
    fontWeight: 'bold',
  },
  text:{
    lineHeight: '0vw',
    fontSize: '0.8vw',
  },
  subtext:{
    marginTop:'5%',
    fontSize: '0.8vw',
    color:'#D3D3D3',
  },
  descrip:{
    lineHeight: '1.2vw',
    width: '80%',
    paddingLeft: '10%'
  },
  button:{
    display: 'flex',
    flexDirection: 'column', /* Esto coloca los elementos en una columna */
    alignItems: 'center' 
  },
  PetContainer: {
    lineHeight: '1.2vw',
    width: '90%',
    paddingLeft: '1%',
    marginLeft:'0.5vw'
  },
  CarContainer:{
    lineHeight: '1.2vw',
    width: '85%',
    paddingLeft: '1%',
    marginInline:'1vw'
  }, 
  LifeContainer:{
    lineHeight: '1.2vw',
    width: '60%',
    paddingLeft: '1%',
    marginInline:'3vw'
  }, 
  ButtonBox: {
    backgroundColor: '#6937F4',
    color: 'white',
    fontSize: '20px',
    marginTop: '20%',
    marginBottom:'10%',
    textAlign: 'center',
    borderRadius: '5px',
    cursor: 'pointer',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor:  '#6937F4',
    boxShadow: '5px 5px 20px -5px  #6937F4',
  },
  TextButtonBox: {
    fontSize: '10px',
    margin: '8px 8px',
  }
};

export default Descriptionhom;

