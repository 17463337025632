import React from 'react';
import '../../App.css';

import ComponentNueve from '../../assets/icons/car/Component 9.png';
import ComponentDiez from '../../assets/icons/car/Component 10.png';
import ComponentOnce from '../../assets/icons/car/Component 11.png';
import ComponentDoce from '../../assets/icons/car/Component 12.png';
import ComponentTrece from '../../assets/icons/car/Component 13.png'


function ServiceCarMobile() {
  return (
    <div className='infoServiceCar'>
    <div className='container-service-car'>
      <div className='containerService'>
        <div className='imgBordIcon2'>
          <img src={ComponentNueve}   className='imgIconCar'/>
        </div>        
        <h1 className='TitleCard'>Swift resolution <br/> of claims</h1>
        <text className='TextCard'>Providing additional information <br/> to expedite the processing  of <br/> your claim.</text>
      </div>
      <div className='containerService'>
        <div className='imgBordIcon2'>
          <img src={ComponentDiez}   className='imgIconCar'/>
        </div>        
        <h1 className='TitleCard'>Fair rates tailored <br/> by your driving habits.</h1>
        <text className='TextCard'>Earn a reduced premium by <br/> driving better and receiving <br/> a discount.</text>
      </div>
      <div className='containerService'>
        <div className='imgBordIcon2'>
          <img src={ComponentOnce}   className='imgIconCar'/>
        </div>        
        <h1 className='TitleCard'>We'll plant trees to  <br/>mitigate your car's impact</h1>
        <text className='TextCard'>Allow us to reduce your car's  <br/>  carbon footprint through <br/>  tree planting.</text>
      </div>
      <div className='containerService'>  
        <div className='imgBordIcon2'>
          <img src={ComponentDoce}   className='imgIconCar'/>
        </div>   
        <h1 className='TitleCard'>Emergency  <br/> collision aid</h1>
        <text className='TextCardRenter4'>We'll detect accidents<br/> and assist  you promptly.</text>
      </div>
      <div className='containerService'>
        <div className='imgBordIcon2'>
          <img src={ComponentTrece}   className='imgIconCar'/>
        </div>        
        <h1 className='TitleCard'>Complimentary  <br/> roadside aid</h1>
        <text className='TextCardRenter4'>Towing, jump-starts,tire  changes,  <br/>  and winching - all on us!</text>
      </div>
    </div>
    </div>
  )
}

export default ServiceCarMobile
