import React from 'react';
import '../../App.css';

import ComponentDos from '../../assets/icons/homeOwners/iconoladron_1.png';
import ComponentCuatro from '../../assets/icons/renders/Comp4.png';
import ComponentCinco from '../../assets/icons/renders/Comp5.png';
import ComponentSeis from '../../assets/icons/homeOwners/Comp6.png';
import ComponentSiete from '../../assets/icons/homeOwners/Comp7.png';
import ComponentOcho from '../../assets/icons/homeOwners/Comp8.png';


function ServiceHomeMobile() {
  return (
    <>
    <div className='InfoCard'>
      <div className='containerCard'>
        <div className='imgBordIcon'>
          <img src={ComponentSeis}  className='imgIcon13'/>
        </div>
        <div className='ContainerText'>
        <h1 className='TitleCard'>If your residence suffers <br/>damage</h1>
        <text className='TextCard'>We'll handle the expenses for <br/>rebuilding your home.</text>
        </div>
      </div>
      <div className='containerCard'>
        <div className='imgBordIcon'>
          <img src={ComponentSiete}  className='imgIcon13'/>
        </div>
        <div className='ContainerText'>
        <h1 className='TitleCard'>If a tree collapses onto <br/>your shed</h1>
        <text className='TextCard'>We'll assist in covering the expenses for <br/> repairing structures on your property,  <br/> such as sheds and garages.</text>
        </div>
      </div>
      <div className='containerCard'>
        <div className='imgBordIcon'>
          <img src={ComponentOcho}  className='imgIcon13'/>
        </div>
        <div className='ContainerText'>
        <h1 className='TitleCard'>If your residence is  unfit for living</h1>
        <text className='TextCard'>We'll take care of costs until you recover.</text>
        </div>
      </div>
      <div className='containerCard'>
        <div className='imgBordIcon'>
          <img src={ComponentDos}  className='imgIcon'/>
        </div>
        <div className='ContainerText'>
        <h1 className='TitleCard'>If your belongings are stolen</h1>
        <text className='TextCard'>We'll assist in covering replacement <br/> costs, even if it  occurs while you’re <br/>outside your home.</text>
        </div>      
      </div>
      <div className='containerCard'>
        <div className='imgBordIcon'>
          <img src={ComponentCuatro}  className='imgIcon'/>
        </div> 
        <div className='ContainerText'>       
        <h1 className='TitleCard'>If you face a liability lawsuit</h1>
        <text className='TextCard'>You'll require legal representation, and <br/> we'll assist in covering those <br/>expenses.</text>
      </div>
      </div>
      <div className='containerCard'>
        <div className='imgBordIcon'>
          <img src={ComponentCinco}  className='imgIcon'/>
        </div> 
        <div className='ContainerText'>       
        <h1 className='TitleCard'>If a visitor sustains an injury</h1>
        <text className='TextCard'>You might end up responsible for their <br/> medical expenses, but we'll assist in <br/> covering those costs.</text>
        </div>
      </div>
    </div>
    </>
  )
}

export default ServiceHomeMobile
