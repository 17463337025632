import React from 'react'
import '../../App.css';


import ComponentVeintiTres from '../../assets/icons/pet/Diagnostics.png';
import ComponentVeintiCuatro from '../../assets/icons/pet/Procedures.png';
import ComponentVeintiCinco from '../../assets/icons/pet/Medication.png';


function ServicePetMobile() {
  return (
    <>
    <div className='InfoCard'>
      <div className='containerCard'>
        <div className='imgBordIcon'>
          <img src={ComponentVeintiTres} className='imgIcon'/>
        </div>      
        <div className='ContainerText'>  
        <h1 className='TitleCard'>DIAGNOSTICS</h1>
        <text className='TextCard'>Blood tests, urinalysis, x-rays, MRIs,<br/>  labwork, CT scans, and ultrasounds</text>
        </div>
      </div>
      <div className='containerCard'>
        <div className='imgBordIcon'>
          <img src={ComponentVeintiCuatro} className='imgIcon'/>
        </div>  
        <div className='ContainerText'>  
        <h1 className='TitleCard'>PROCEDURES</h1>
        <text className='TextCard'>Outpatient, specialty and emergency <br/>care, hospitalization, and surgery</text>
        </div>
      </div>
      <div className='containerCard'>
        <div className='imgBordIcon'>
          <img src={ComponentVeintiCinco} className='imgIcon'/>
        </div>
        <div className='ContainerText'>          
        <h1 className='TitleCard'>MEDICATION</h1>
        <text className='TextCard3'>Injections and prescription meds</text>
        </div>  
      </div>
    </div>
    </>
 
  )
}

export default ServicePetMobile
