import React from 'react';

const Testimonials= ({item}) => {
  console.log("info", item);
  
  return (
      <div style={styles.CardContainer}>
        <div style={styles.HeaderContainer}>
          <div style={styles.Header}>
            <img src={item.icon} style={styles.ImgUSer} ></img>
          </div>
          <div style={styles.HeaderTitle}> 
            <text style={styles.title}>{item.title}</text>
            <p style={styles.text}>{item.subtitle}</p>  
          </div>  
        </div>     
        <div style={styles.descripSub}>
          <p>{item.description}</p>
        </div>
      </div>
  );
};

const styles = {
  CardContainer:{
    fontFamily: "Open Sans",
    backgroundColor: 'white',
    background: 'rgb(255, 255, 255)',
    height: '30%',
    width: '700px',
    boxShadow: 'rgba(0, 0, 0, 0.06) 11px 1px 25px 10px, rgba(0, 0, 0, 0.16) 0px 31px 30px -22px',
    borderRadius: '10px',
    padding: '2% 2%',
    marginRight: '30px',
    marginBottom: '50px',
    boxSizing: 'content-box',
  },
  HeaderTitle:{
    marginLeft:'5%',
    display: 'flex',
    flexDirection: 'column', 
    width:'15vw',
  },
  HeaderContainer:{
    display: 'flex', // Alineación horizontal de los elementos hijos
  },
  ImgUSer: {
    marginLeft:'10px',
    width: '50px',
    height: '50px',
    borderRadius: '50%',
    objectFit: 'cover',
  },
  Header:{
     marginRight: '5px', // Espacio entre Header y HeaderTitle
  },
  title:{
    fontSize: '1.2vw',
    fontWeight: 'bold',
    paddingLeft:'3%',
    paddingTop:'2%',
  },
  text:{
    fontSize: '1vw',
    marginLeft:'20px',
    color: '#A1ACB3',
    paddingLeft:'3%',
    margin: 0
  },
  descrip:{
    width:'20vw',
  },
  descripSub:{
    height:'50px',
    marginLeft:'3%',
    marginTop:'5px',
    fontSize: '0.8vw',
    paddingBottom:'1vw'
  }
};

export default Testimonials;
