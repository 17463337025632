import React from 'react'
import Description from './Description';
import Renter from '../assets/gif/home/APTO.gif';
import Homeowner from '../assets/gif/home/CASA.gif';
import Car from "../assets/gif/home/CARRO.gif";
import TermLife from "../assets/gif/home/CUADRO.gif";
import Pet from "../assets/gif/home/MASCOTAS.gif"

function Box() {
  const items =[
    {"Image":Renter,"Servicie":"Renters","Description":"Protection for your things, in and out your home","Price":"FROM $5/MO","Link":"/renters"},
    {"Image":Homeowner,"Servicie":"Homeowner","Description":"Safeguarding your residence and belongings","Price":"FROM $25/MO","Link":"/homeowner"},
    {"Image":Car,"Servicie":"Car","Description":"Safeguard your vehicle, passengers, and the environment.","Price":"FROM $30/MO","Link":"/car"},
    {"Image":TermLife,"Servicie":"Term life","Description":"Protecting the people you love","Price":"FROM $9/MO","Link":"/life"},
    {"Image":Pet,"Servicie":"Pet","Description":"Coverage for the health of your beloved furry family members.","Price":"FROM $9/MO","Link":"/pet"}]

  return (
    <div >
      {items.map((item) => (
          <Description key={item} item={item} />
        ))}
    </div>
)}


export default Box
