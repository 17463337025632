import React from 'react';

const TestimonialsMobile = ({ item }) => {
  return (
    <div style={styles.CardContainer}>
      <div style={styles.HeaderContainer}>
        <div style={styles.Header}>
        <img src={item.icon} style={styles.ImgUSer} ></img>
        </div>
        <div style={styles.HeaderTitle}>
          <text style={styles.title}>{item.title}</text>
          <p style={styles.text}>{item.subtitle}</p>
        </div>
      </div>
      <div style={styles.descripSub}>
        <p>{item.description}</p>
      </div>
    </div>
  );
};

const styles = {
  CardContainer: {
    fontFamily: "Open Sans",
    background: 'rgb(255, 255, 255)',
    boxShadow: 'rgba(0, 0, 0, 0.06) 0px 1px 8px 0px, rgba(0, 0, 0, 0.16) 0px 31px 30px -18px',
    borderRadius: '10px',
    padding: '20px',
    marginTop: '160px',
    marginRight: '15px',
    marginBottom: '40px',
    boxSizing: 'content-box',
  },
  HeaderTitle: {
    marginLeft: '5%',
    display: 'flex',
    flexDirection: 'column',
  },
  HeaderContainer: {
    width: '300px',
    height: '60px',
    marginTop:'2px',
    display: 'flex', // Alineación horizontal de los elementos hijos
  },
  ImgUSer: {
    marginLeft:'20px',
    width: '50px',
    height: '50px',
    borderRadius: '50%',
    objectFit: 'cover',
  },
  Header: {
    marginRight: '12px', // Espacio entre Header y HeaderTitle
  },
  title: {
    fontSize: '20px',
    fontWeight: 'bold',
    paddingTop: '5px',
  },
  text: {
    fontSize: '15px',
    color: '#A1ACB3',
    margin: 0
  },
  descrip: {
    width: '200px',
  },
  descripSub: {
    width: '90%',
    marginTop:'10px',
    marginLeft: '5%',
    fontSize: '14px'
  }
};

export default TestimonialsMobile;
