import React, { useState, useEffect } from "react";
import { FaHeart } from "react-icons/fa"; 
import "../App.css";

const colors = {
  red: "#6937F4", // Cambia el color a rojo
  grey: "#a9a9a9",
};

function HeartMobile() { // Cambia el nombre del componente
  const [currentValue, setCurrentValue] = useState(0);
  const [hoverValue, setHoverValue] = useState(undefined);
  const hearts = Array(5).fill(0); 

  useEffect(() => {
    const timer = setInterval(() => {
      if (currentValue < 5) {
        setCurrentValue(currentValue + 1);
      } else {
        setCurrentValue(0); // Reinicia después de llegar a 5
      }
    }, 500);
  
    return () => clearInterval(timer);
  }, [currentValue]);

  return (
    <div style={styles.container}>
      <div style={styles.hearts}>
        {hearts.map((_, index) => {
          const heartValue = index + 1;
          const isFilled = (hoverValue || currentValue) >= heartValue;
          const heartColor = isFilled ? colors.red : colors.grey;
          return (
            <FaHeart
              key={index}
              size={"16%"}
              className={`heart ${isFilled ? "filled" : ""}`}
              color={heartColor}
              style={{
                marginRight: 10,
                cursor: "pointer",
              }}
            />
          );
        })}
      </div>
    </div>
  );
}

const styles = {
  container: {
    marginRight: "5%",
    flexDirection: "column",
  },
  hearts: {
    flexDirection: "row",
  },
};

export default HeartMobile;
