import React, { useState, useEffect } from "react";
import { FaStar } from "react-icons/fa";
import "../App.css";

const colors = {
  orange: "#6937F4",
  grey: "#a9a9a9",
};

function StarMobile() {
  const [currentValue, setCurrentValue] = useState(0);
  const [hoverValue, setHoverValue] = useState(undefined);
  const stars = Array(5).fill(0);

  useEffect(() => {
    // Cambia automáticamente el valor consecutivamente cada 2 segundos
    const timer = setInterval(() => {
      if (currentValue < 5) {
        setCurrentValue(currentValue + 1);
      } else {
        setCurrentValue(0); // Reinicia después de llegar a 5
      }
    }, 500);
  
    return () => clearInterval(timer);
  }, [currentValue]);

  return (
    <div style={styles.container}>
      <div style={styles.stars}>
        {stars.map((_, index) => {
          const starValue = index + 1;
          const isFilled = (hoverValue || currentValue) >= starValue;
          const starColor = isFilled ? colors.orange : colors.grey;
          return (
            <FaStar
              key={index}
              size={"18%"}
              className={`star ${isFilled ? "filled" : ""}`}
              color={starColor}
              style={{
                marginRight: 3,
                cursor: "pointer",
              }}
            />
          );
        })}
      </div>
    </div>
  );
}

const styles = {
  container: {
    flexDirection: "column",
  },
  stars: {
    flexDirection: "row",
  },
};

export default StarMobile;
