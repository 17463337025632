import React,{useState} from 'react'
import '../../App.css'

function QuestionsLife() {
  const questions = [
    {"Question":"Will you be around in 30 years?","Answer":"Our term life insurance is provided by Assurity Company for Life and Health Insurance, with a history spanning over 140 years!"},
    {"Question":"Who can apply for coverage?","Answer":"Individuals generally in good health between the ages of 18 and 70 are eligible to apply. If you have a history of serious health conditions such as heart disease or cancer, you may not qualify for a policy at the moment."},
    {"Question":"Can I cancel any time?","Answer":"Absolutely! You receive a complete refund if you cancel within the initial 30 days. Following that, no further charges will be applied."},
    {"Question":"Term life vs Whole life insurance?","Answer":"While term life insurance provides coverage for 10 to 30 years, whole life insurance covers you for your entire life. Additionally, whole life policies come with a guaranteed cash value that increases over time, making them generally more expensive."},
    {"Question":"How much does life insurance cost at FIG","Answer":"Coverage begins at $9 monthly. Premiums are determined by your chosen coverage amount and term, along with factors like age, lifestyle, health, and location."},
    {"Question":"No medical exam? Really?","Answer":"Absolutely! Gazzelle, our amiable AI bot, will inquire about your health and lifestyle. Your responses enable us to save you time and eliminate the need for traditional medical exams."},
    {"Question":"Why FIG?","Answer":"FIG was established with the goal of building the world's most adored insurance brand. Our focus is entirely on you, our valued customer. Additionally, we boast top ratings and uphold a 'world-class' Net Promoter Score, surpassing 75."},
    {"Question":"Will my premium change over time?","Answer":"Nope. The cool thing about term life policies is that your premium remains fixed for the entire term (10 to 30 years)."},
    {"Question":"What kind of life insurance does FIG offer?","Answer":"We provide term life insurance with options ranging from 10 to 30 years. Safeguard your loved ones with coverage up to $10,000,000."},
  ]

  const [activeBox, setActiveBox] = useState(null);

  return (
    <div style={styles.container}>
      {questions.map((question,index) => (
        <div style={styles.BoxContainer}
          key={index}
          className={`box ${activeBox === index ? 'active' : 'inactive'}`}
          onMouseEnter={() => setActiveBox(index)}
          onMouseLeave={() => setActiveBox(null)}>
          <div style={styles.titleContainer}>
          <text style={styles.title}>{question.Question}</text>
          </div>
          <div style={styles.textContainer}>
            <p style={styles.text}>{question.Answer}</p>
          </div>
        </div>
      ))}
    </div>
  )
}

const styles = {
  container: {
    display: 'inline-block',
    justifyContent: 'center', // Alinea horizontalmente al centro
    maxWidth: '2000px', // Establece un ancho máximo para el contenedor// Centra el contenedor horizontalmente en la página
  },
  BoxContainer:{
    marginTop:'3%',
    fontFamily: "Open Sans",
    backgroundColor: 'white',
    height:'22vw',
    borderRadius:'15px',
    marginRight:'20px',
    display:'inline-block',
    width: '28%', /* Establece el ancho deseado del card */
    overflow: 'hidden' ,
  },
  title:{
    fontSize:'1.4vw',
    fontWeight:'bolder',
    
  },
  titleContainer:{
    lineHeight: '1.5vw',
    width:'83%',
    padding:'2vw 1vw 0vw 2.5vw'
  },
  textContainer:{
    lineHeight: '1.5vw',
    width:'80%',
    padding:'1vw 1vw 1vw 2.5vw '
  },
  text:{
    fontSize:'1vw',
    color: '#595656',
    margin: 0
  }
}
export default QuestionsLife
