import React from 'react'
import { useMediaQuery } from 'react-responsive';
import '../../App.css'

import Navbar from '../Header/Navbar';
import Footer from '../Footer'
import FooterMobile from '../FooterMobile';

function PrivacyPolicy() {
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
  const isTablet = useMediaQuery({ query: '(min-width: 601px) and (max-width: 1200px)' });

  return (
  <>
    <Navbar/>
    <scroll>
      <>
      {isMobile || isTablet ? (
        <div style={styles.containerMobile}>
        <text style={styles.titleMobile}>Privacy Policy</text>
        <div style={styles.textContainerMobile}>
        <h2 style={styles.subtileMobile}>Introduction</h2>
        <p style={styles.textMobile}>FIG ("we", "us", "our") is committed to protecting and respecting your privacy. This Privacy Policy explains
        how we collect, use, disclose, and secure personal data when you visit our website  <a href='/'> www.fig.biz </a> and when 
        you interact with our services.</p>
        <h2 style={styles.subtileMobile}>Personal Information We Collect</h2>
        <p style={styles.textMobile}>We may collect and process the following personal information about you:</p>
        <li style={styles.textMobile}><b>Contact Information: </b>
        <br/> Name, email address, phone number, and mailing address.</li>
        <li style={styles.textMobile}><b>Usage Information: </b>
        <br/> Information about how you use our website and services.</li>
        <h2 style={styles.subtileMobile}>Why We Collect It</h2>
        <p style={styles.textMobile}>We collect personal information for the following purposes:</p>
        <li style={styles.textMobile}><b>To Provide and Improve Our Services:</b>
        <br/>Ensuring our website and services are functioning correctly and improving them based on your feedback.</li>
        <li style={styles.textMobile}><b>To Communicate with You:</b>
        <br/>Responding to your inquiries, sending administrative information, and providing updates about our services.</li>
        <li style={styles.textMobile}><b>To Comply with Legal Obligations:</b>
        <br/>Ensuring compliance with applicable laws and regulations.</li>
        <h2 style={styles.subtileMobile}>How We Use It</h2>
        <p style={styles.textMobile}>Your personal information is used for the purposes outlined above. We do not sell your personal information <br/>
        to third parties. Any data we share is done so only with your explicit consent, and solely for purposes related <br/>
        to the services we provide.</p>
        <h2 style={styles.subtileMobile}>Data Sharing and Disclosure</h2>
        <p style={styles.textMobile}>We do not share your personal information with third parties, except in the following circumstances:</p>
        <li style={styles.textMobile}><b>With Your Consent: </b>
        <br/>When you provide explicit consent to share the information.</li>
        <li style={styles.textMobile}><b>For Legal Reasons: </b>
        <br/>When required by law, regulation, or legal process.</li>
        <h2 style={styles.subtileMobile}>Your Data Rights</h2>
        <p style={styles.textMobile}>You have the following rights regarding your personal data:</p>
        <li style={styles.textMobile}><b>Access:</b>
        <br/>Request access to your personal data.</li>
        <li style={styles.textMobile}><b>Correction:</b>
        <br/>Request correction of any inaccurate data we hold about you.</li>
        <li style={styles.textMobile}><b>Deletion:</b>
        <br/>Request deletion of your personal data.</li>
        <li style={styles.textMobile}><b>Restriction:</b>
        <br/>Request deletion of your personal data.</li>
        <li style={styles.textMobile}><b>Data Portability:</b>
        <br/>Request transfer of your personal data to another party.</li>
        <li style={styles.textMobile}><b>Objection:</b>
        <br/>Object to the processing of your personal data.</li>
        </div>
        </div>
      ):(
        <div style={styles.container}>
        <text style={styles.title}>Privacy Policy</text>
        <div style={styles.textContainer}>
        <h2 style={styles.subtile}>Introduction</h2>
        <p style={styles.text}>FIG ("we", "us", "our") is committed to protecting and respecting your privacy. This Privacy Policy explains <br/>
        how we collect, use, disclose, and secure personal data when you visit our website <a href='/'> www.fig.biz </a>  and when <br/>
        you interact with our services.</p>
        <h2 style={styles.subtile}>Personal Information We Collect</h2>
        <p style={styles.text}>We may collect and process the following personal information about you:</p>
        <li style={styles.text}><b>Contact Information: </b>Name, email address, phone number, and mailing address.</li>
        <li style={styles.text}><b>Usage Information: </b>Information about how you use our website and services.</li>
        <h2 style={styles.subtile}>Why We Collect It</h2>
        <p style={styles.text}>We collect personal information for the following purposes:</p>
        <li style={styles.text}><b>To Provide and Improve Our Services:</b> Ensuring our website and services are functioning correctly and <br/>
        improving them based on your feedback.</li>
        <li style={styles.text}><b>To Communicate with You: </b>Responding to your inquiries, sending administrative information,<br/>
         and providing updates about our services.</li>
        <li style={styles.text}><b>To Comply with Legal Obligations: </b>Ensuring compliance with applicable laws and regulations.</li>
        <h2 style={styles.subtile}>How We Use It</h2>
        <p style={styles.text}>Your personal information is used for the purposes outlined above. We do not sell your personal information <br/>
        to third parties. Any data we share is done so only with your explicit consent, and solely for purposes related <br/>
        to the services we provide.</p>
        <h2 style={styles.subtile}>Data Sharing and Disclosure</h2>
        <p style={styles.text}>We do not share your personal information with third parties, except in the following circumstances:</p>
        <li style={styles.text}><b>With Your Consent: </b>When you provide explicit consent to share the information.</li>
        <li style={styles.text}><b>For Legal Reasons: </b>When required by law, regulation, or legal process.</li>
        <h2 style={styles.subtile}>Your Data Rights</h2>
        <p style={styles.text}>You have the following rights regarding your personal data:</p>
        <li style={styles.text}><b>Access: </b>Request access to your personal data.</li>
        <li style={styles.text}><b>Correction: </b>Request correction of any inaccurate data we hold about you.</li>
        <li style={styles.text}><b>Deletion: </b>Request deletion of your personal data.</li>
        <li style={styles.text}><b>Restriction: </b>Request deletion of your personal data.</li>
        <li style={styles.text}><b>Data Portability: </b>Request transfer of your personal data to another party.</li>
        <li style={styles.text}><b>Objection: </b>Object to the processing of your personal data.</li>
        </div>
        </div>
      )}
      </>
    </scroll>
    <div>
          {isMobile || isTablet ? <FooterMobile /> : <Footer />}
     </div> 
  </>
  )
}

const styles = {
  //Web
  container:{
    display: 'grid',
    marginBottom: '100px',
    marginTop:'8rem',
    textAlign: 'center',
    gridTemplateColumns: 'repeat(12, 1fr)'
  },
  title:{
    fontFamily: '"PT Serif", serif',
    fontWeight: 700,
    fontStyle: 'normal',
    gridColumn:  '2/12',
    fontSize:'3.5rem',
  },
  textContainer:{
    gridColumn:  '3/12',
    textAlign: 'left',
    fontFamily:"Open Sans",
    marginTop:'2rem',
    marginLeft:'5%',
    lineHeight: 1.8,
  },
  subtile:{
    fontSize:'30px',
  },
  text:{
    fontSize:'18px'
  },
  //Mobile
  containerMobile:{
    display: 'grid',
    marginBottom: '100px',
    marginTop:'3rem',
    textAlign: 'center',
    gridTemplateColumns: 'repeat(12, 1fr)'
  },
  titleMobile:{
    fontFamily: '"PT Serif", serif',
    fontWeight: 700,
    fontStyle: 'normal',
    gridColumn:  '2/12',
    fontSize:'3rem',
  },
  textContainerMobile:{
    gridColumn:  '2/12',
    textAlign: 'left',
    fontFamily:"Open Sans",
    marginLeft:'5%',
    lineHeight: 1.8,
  },
  subtileMobile:{
    fontSize:'25px',
  },
  textMobile:{
    fontSize:'15px'
  },
}
export default PrivacyPolicy
