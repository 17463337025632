import React from 'react'
import '../../App.css';


import ComponentUno from '../../assets/icons/renders/Comp1.png';
import ComponentDos from '../../assets/icons/renders/Comp2.png';
import ComponentTres from '../../assets/icons/renders/Comp3.png';
import ComponentCuatro from '../../assets/icons/renders/Comp4.png';
import ComponentCinco from '../../assets/icons/renders/Comp5.png';


function ServiceRentersMobile() {
  return (
    <>
    <div className='InfoCard'>
      <div className='containerCard'>
        <div className='imgBordIcon'>
          <img src={ComponentUno} className='imgIcon'/>
        </div>
        <div className='ContainerText'>
        <h1 className='TitleCard'>If someone teals your bicycle</h1>
        <text className='TextCard'>Coverage for crime and vandalism <br/> reimburses you for your belongings in <br/> the event of damage or theft.</text>
        </div>
      </div>
      <div className='containerCard'>
        <div className='imgBordIcon'>
          <img src={ComponentDos} className='imgIcon2'/>
        </div>
        <div className='ContainerText'>
        <h1 className='TitleCard'>If water leakage damages <br/>your things</h1>
        <text className='TextCard'>Water damage coverage safeguards you <br/> in case your electronics get wet or your <br/> pipes create a mess.</text>
        </div>
      </div>
      <div className='containerCard'>
        <div className='imgBordIcon'>
          <img src={ComponentTres} className='imgIcon3'/>
        </div>
        <div className='ContainerText'>
        <h1 className='TitleCard'>If your belongings are <br/>destroyed by fire</h1>
        <text className='TextCard'>Fire and smoke coverage ensures you're <br/> protected when things heat up</text>
        </div>
      </div>
      <div className='containerSubtitle'>
      <text className='SubTitleCard'>Also, shield yourself from damages caused by someone else's accidents occurring in your home</text> 
      </div>
      <div className='containerCard'>
        <div className='imgBordIcon'>
          <img src={ComponentCuatro} className='imgIcon4'/>
        </div>
        <div className='ContainerText'>
        <h1 className='TitleCard'>If someone sues you for <br/>compensation</h1>
        <text className='TextCard'>Liability coverage guards against lawsuits <br/> arising from accidents in your home. <br/>We'll provide legal representation and <br/>cover associated expenses</text>
        </div>
      </div>
      <div className='containerCard'>
        <div className='imgBordIcon'>
          <img src={ComponentCinco} className='imgIcon'/>
        </div>
        <div className='ContainerText'>
        <h1 className='TitleCard'>If someone is injured in your <br/> house</h1>
        <text className='TextCard'>We'll assist in covering medical expenses<br/> if someone has an accident or sustains <br/> an injury while at your home.</text>
        </div>
      </div>
    </div>
    
    </>
 
  )
}

export default ServiceRentersMobile;