import React,{useState} from 'react'
import '../../App.css';

import FlechaAbajo from '../../assets/img/flechaAbajo.png';
import FlechaArriba from '../../assets/img/flechaArriba.png'

function QuestionsLifeMobile() {
  const questions = [
    {"Question":"Will you be around in 30 years?","Answer":"Our term life insurance is provided by Assurity Company for Life and Health Insurance, with a history spanning over 140 years!"},
    {"Question":"Who can apply for coverage?","Answer":"Individuals generally in good health between the ages of 18 and 70 are eligible to apply. If you have a history of serious health conditions such as heart disease or cancer, you may not qualify for a policy at the moment."},
    {"Question":"Can I cancel any time?","Answer":"Absolutely! You receive a complete refund if you cancel within the initial 30 days. Following that, no further charges will be applied."},
    {"Question":"Term life vs Whole life insurance?","Answer":"While term life insurance provides coverage for 10 to 30 years, whole life insurance covers you for your entire life. Additionally, whole life policies come with a guaranteed cash value that increases over time, making them generally more expensive."},
    {"Question":"How much does life insurance cost at FIG","Answer":"Coverage begins at $9 monthly. Premiums are determined by your chosen coverage amount and term, along with factors like age, lifestyle, health, and location."},
    {"Question":"No medical exam? Really?","Answer":"Absolutely! Gazzelle, our amiable AI bot, will inquire about your health and lifestyle. Your responses enable us to save you time and eliminate the need for traditional medical exams."},
    {"Question":"Why FIG?","Answer":"FIG was established with the goal of building the world's most adored insurance brand. Our focus is entirely on you, our valued customer. Additionally, we boast top ratings and uphold a 'world-class' Net Promoter Score, surpassing 75."},
    {"Question":"Will my premium change over time?","Answer":"Nope. The cool thing about term life policies is that your premium remains fixed for the entire term (10 to 30 years)."},
    {"Question":"What kind of life insurance does FIG offer?","Answer":"We provide term life insurance with options ranging from 10 to 30 years. Safeguard your loved ones with coverage up to $10,000,000."},
  ]

  const [expandedQuestions, setExpandedQuestions] = useState([]);

  const handleToggle = (index) => {
    setExpandedQuestions(prevState =>
      prevState.includes(index)
        ? prevState.filter(i => i !== index)
        : [...prevState, index]
    );
  };
  return (
<div className='container'>
      {questions.map((question, index) => (
        <div key={index}>
          <div className='header' onClick={() => handleToggle(index)}>
            <span className='title'>{question.Question}</span>
            <img
              src={expandedQuestions.includes(index) ? FlechaAbajo : FlechaArriba}
              alt={expandedQuestions.includes(index) ? 'flecha hacia abajo' : 'flecha hacia arriba'}
              className='icon'
            />
          </div>
          {expandedQuestions.includes(index) && (
            <div className={`content ${expandedQuestions.includes(index) ? 'expanded' : ''}`}>
              <p className='text'>{question.Answer}</p>
            </div>
          )}
        </div>
      ))}
    </div>
  )
}

export default QuestionsLifeMobile
