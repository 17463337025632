import React, { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import '@fontsource/open-sans';
import '../App.css';

import LifeImageUno from '../assets/img/Life/Life_Ilus1.jpg';
import LifeImageUnoMobile from '../assets/img/Life/Life-Iluscolor_mobile1.png'
import LifeImageDos from '../assets/img/Life/Life_Ilus2.jpg';
import LifeImageDosMobile from '../assets/img/Life/Life-Iluscolor_mobile2.png';
import LifeImageTres from '../assets/img/Life/Life_Ilus3.jpg';
import LifeImageTresMobile from '../assets/img/Life/Life-Iluscolor_mobile3.png';

import Navbar from '../components/Header/Navbar';
import Button from '../components/Button';
import Footer from '../components/Footer';
import FooterMobile from '../components/FooterMobile';

import Heart from '../Utils/Heart';
import Carousel from '../Utils/Carousel';
import ImageSlider from '../Utils/Life/SliderLife';
import QuestionsLife from '../Utils/Life/QuestionsLife';
import HeartMobile from '../Utils/HeartMobile';
import CarouselMobile from '../Utils/CarouselMobile';
import QuestionsLifeMobile from '../Utils/Life/QuestionsLifeMobile';

function Life() {
  const [currentImage, setCurrentImage] = useState(1);
  const [currentColor, setCurrentColor] = useState('#333333');
  const [isAnimating, setIsAnimating] = useState(false);
  const [isPageReloaded, setIsPageReloaded] = useState(true);
  const [isSliderPlaying, setIsSliderPlaying] = useState(false);
  const isMobileOrTablet = useMediaQuery({ query: '(max-width: 1200px)' });

  const colors = ['#333333', '#494949', '#f5f6fb'];

  useEffect(() => {
    const handleScroll = () => {
      if (!isAnimating) {
        setIsAnimating(true);
        requestAnimationFrame(() => {
          const scrollPosition = window.scrollY;
          const cardLifeFooter = document.querySelector('.CardLifeFooter');
          const cardLifeFooterTop = cardLifeFooter.offsetTop;
          const cardLifeFooterHeight = cardLifeFooter.offsetHeight;

          if (
            scrollPosition > cardLifeFooterTop &&
            scrollPosition < cardLifeFooterTop + cardLifeFooterHeight
          ) {
            setCurrentImage((prevImage) => {
              const nextImage = prevImage + 1;
              if (nextImage <= 3) {
                setCurrentColor(colors[nextImage - 1]);
                setTimeout(() => {
                  setIsAnimating(false);
                }, 200);
                return nextImage;
              } else {
                setIsAnimating(false);
                return prevImage;
              }
            });
            setIsSliderPlaying(true); // Start slider playback
          } else if (scrollPosition < cardLifeFooterTop) {
            setCurrentImage(1);
            setCurrentColor(colors[0]);
            setIsAnimating(true);
            setTimeout(() => {
              setIsAnimating(false);
            }, 200);
            setIsSliderPlaying(false); // Stop slider playback
          } else {
            setIsAnimating(false);
          }
        });
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isAnimating, currentImage]);

  useEffect(() => {
    if (isPageReloaded) {
      setCurrentImage(1);
      setCurrentColor(colors[0]);
      setIsPageReloaded(false);
    }
  }, [isPageReloaded]);

  const imageMap = isMobileOrTablet ? {
    1: LifeImageUnoMobile,
    2: LifeImageDosMobile,
    3: LifeImageTresMobile,
  } : {
    1: LifeImageUno,
    2: LifeImageDos,
    3: LifeImageTres,
  };

  return (
    <div className='App'>
      <Navbar />
      <scroll>
        <div className='CardLife1'>
          <p className='TitleHome1'>Next Generation Term <br />Life Insurance </p>
          <text className='SubTitleHome1'>No medical tests. Everything is instantaneous. Adored by all.</text>
          <div className='buttonHome'>
            <Button />
          </div>
        </div>
        <div className='CardHome2'>
          <text className='TitleHome4'><strong>Provided by the highest-rated insurance company in America.</strong></text>
          <text className='SubTitleHome2'>FIG earned top ratings from Supermoney, and others</text>
          <div className={isMobileOrTablet ? 'RatingMobileLife':'Rating'}>
          {isMobileOrTablet ? <HeartMobile /> : <Heart />}   
          </div>
          <div className={isMobileOrTablet ? 'CarouselMobileCar':'Carousel'}>
          {isMobileOrTablet ? <CarouselMobile /> : <Carousel />}
          </div>
        </div>
        <div className='CardRenter3'>
          <h1 className='TitleRenter3'>Entirely digital. No physicals. Done in 5 minutes</h1>
          <text className='SubTitleRenter3_1'>Our rapid application process removes the necessity for medical exams by posing a few health questions to assess eligibility.<br/> This means no needles and no waiting.</text>
          <div className='SliderLife'>
            <ImageSlider isSliderPlaying={isSliderPlaying} />
          </div>
        </div>
        <div className='CardRenter4'>
          <h1 className='TitleRenter4'>Good to know...</h1>
          <text className='SubTitleRenter4'>Life insurance can be confusing, so we worked hard to make it simple.<br></br>If you still have questions, you can check out life insurance explained.</text>
          <div className={isMobileOrTablet ? 'BoxRender':'BoxRender'}>
            {isMobileOrTablet ? <QuestionsLifeMobile /> : <QuestionsLife />}
          </div>
        </div>
        <div className={`CardLifeFooter ${isAnimating ? 'animating' : ''}`} style={{ backgroundColor: currentColor }}>
          <h1 className='TitleLifeFooter5' style={{ color: currentColor === '#f5f6fb' ? '#333333' : 'white' }}>Accidental Death & Dismemberment</h1>
          <text className='SubTitleLifeFooter5' style={{ color: currentColor === '#f5f6fb' ? '#333333' : 'white' }}>Pays a death benefit to your loved ones if you die from an accident.</text>
          <div className='buttonHome'>
            <Button />
          </div>
          <div className='ImageLifeFooter'>
          <img src={imageMap[currentImage]} className={`LifeFooter ${isAnimating ? 'fade-in' : ''}`} alt='Life Image' />
          </div>
        </div>
      </scroll>
      {isMobileOrTablet ? <FooterMobile /> : <Footer />}
    </div>
  );
}

export default Life;
