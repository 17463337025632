import React, { useState } from 'react';
import '../../App.css';

import FlechaAbajo from '../../assets/img/flechaAbajo.png';
import FlechaArriba from '../../assets/img/flechaArriba.png'

function QuestionMobile() {
  const questions = [
    {"Question":"What does FIG Renters insurance cover?","Answer":"Your policy includes protection for personal property against theft and damage, as well as coverage for legal claims arising from accidental damage or injury to others. Additionally, if your home becomes uninhabitable due to specific types of damage, we'll assist in covering the expenses of temporary living arrangements."},
    {"Question":"Does FIG offer renters insurance near me?","Answer":"We're glad you asked! FIG’S renters insurance is available in 28 states plus Washington, D.C., and we're still growing. "},
    {"Question":"What if something I own gets stolen outside of my home?","Answer":"You're always protected! Your FIG renters policy extends coverage to your personal property globally. Explore theft coverage details here."},
    {"Question":"What if I want to cancel my policy?","Answer":"Feel free to cancel your policy anytime using the FIG website, and you'll receive a refund for the remaining paid period. No hard feelings!"},
    {"Question":"How much does renters insurance cost?","Answer":"Our affordable renters insurance coverage starts at $5 per month, but your policy price (aka the “premium”) will be affected by the coverage amounts you choose, among other factors. You can get your online renters insurance quote here."},
    {"Question":"How do I file a claim?","Answer":"Our budget-friendly renters insurance begins at $5 per month, but your policy cost, known as the 'premium', is influenced by the coverage levels you select and other factors."},
    {"Question":"I already have a policy with another carrier - can I switch?","Answer":"Certainly! After you join us, we'll notify your previous insurer formally. Upon receipt, they are expected to terminate the remaining term of your policy and reimburse any outstanding payments."},
    {"Question":"How do I add an interested party?","Answer":"All you need to do is tap ‘Add-ons’ under your policy page in the FIG app or your policy on your account page here and select ‘Interested Party’. Fill in their name, address, and email and you’re all set!"},
    {"Question":"What is Extra Coverage?","Answer":"Extra Coverage provides advanced protection for items like jewelry, fine art, cameras, bicycles, and musical instruments. With deductible-free claims and coverage for accidental loss and damage, you can apply for Extra Coverage when signing up for a policy or at any time after activating it."},
    {"Question":"What bundle options does FIG offer?","Answer":"Besides renters insurance, FIG provides policies for homeowners, cars, pets, and term life. You can access an automatic bundle discount with various combinations, such as car and renters, making it a convenient way to streamline your insurance requirements and save money."},
    {"Question":"Does FIG offer discounts for renters insurance?","Answer":"Qualify for an automatic discount by bundling your policies. Additionally, you may receive a discount on your renters policy if your place is equipped with protective devices such as deadbolts, smoke alarms, and burglar alarms."},
    {"Question":"Who needs renters insurance?","Answer":"Renters insurance is essential for all tenants as it provides crucial financial protection at a minimal cost. Your landlord or property manager may mandate renters insurance, and having a renters policy can save you thousands in case of damages such as fires, theft, vandalism, burst pipes, windstorms, and more."},
  ]

  const [expandedQuestions, setExpandedQuestions] = useState([]);

  const handleToggle = (index) => {
    setExpandedQuestions(prevState =>
      prevState.includes(index)
        ? prevState.filter(i => i !== index)
        : [...prevState, index]
    );
  };

  return (
  <div className='container'>
      {questions.map((question, index) => (
        <div key={index}>
          <div className='header' onClick={() => handleToggle(index)}>
            <span className='title'>{question.Question}</span>
            <img
              src={expandedQuestions.includes(index) ? FlechaAbajo : FlechaArriba}
              alt={expandedQuestions.includes(index) ? 'flecha hacia abajo' : 'flecha hacia arriba'}
              className='icon'
            />
          </div>
          {expandedQuestions.includes(index) && (
            <div className={`content ${expandedQuestions.includes(index) ? 'expanded' : ''}`}>
              <p className='text'>{question.Answer}</p>
            </div>
          )}
        </div>
      ))}
  </div>
  );
}

const styles = {

};

export default QuestionMobile;
