import React from "react";
import "./animation.css"; // Importa tu archivo CSS aquí
import '../../App.css'
import heart from '../../assets/icons/home/icono15.png'; // Asegúrate de tener la ruta correcta a tu imagen

function AnimatedImage() {
  return (
    <img
      width={'8px'}
      height={'8px'}
      src={heart}
      className="animated-image" // Agrega una clase CSS para aplicar la animación
    />
  );
}

export default AnimatedImage;
