import React, { useState, useEffect, useRef } from 'react';
import { useMediaQuery } from 'react-responsive';
import './Modal.css';

import Email from '../../assets/icons/IconoEmail.png';
import Instagram from '../../assets/icons/IconoInstagram.png';
import Facebook from '../../assets/icons/IconoFacebook.png';
import Twiter from '../../assets/icons/IconoTwiter.png';

function ModalContact() {
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
  const isTablet = useMediaQuery({ query: '(min-width: 601px) and (max-width: 1200px)' });
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });

  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const formRef = useRef(null);
  const inputRefs = {
    name: useRef(null),
    email: useRef(null),
    subject: useRef(null),
    message: useRef(null)
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCheckboxChange = (e) => {
    setAcceptedTerms(e.target.checked);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!acceptedTerms) {
      alert('You must accept the terms and conditions to submit the form.');
      return;
    }
    console.log('Form data submitted:', formData);
  };

  const handleResize = () => {
    if (formRef.current) {
      formRef.current.style.height = '';
      formRef.current.classList.remove('keyboard-open');
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (inputRefs.name.current) {
      inputRefs.name.current.focus();
    }
  }, []);


  return (
    <div className='modal-container'>
      {isMobile ?(
        <div></div>
      ):(
        <div className='Content-right'>
        <h3 className='contact-title'>Contact Information</h3>
        <div className='contact-mobile'>
          <p className='contact'><img src={Email} className='icon' alt='Email Icon'/> help@fig.biz</p>
          <p className='contact'><img src={Instagram} className='icon' alt='Instagram Icon' />@fig.biz</p>
        </div>
        <div className='contact-mobile'>
          <p className='contact'><img src={Facebook} className='icon' alt='Facebook Icon' />Fig Insurance</p>
          <p className='contact'><img src={Twiter} className='icon' alt='Twitter Icon' />@fig.biz</p>
        </div>
        </div>
      )}
      <div className='Content-left'>
        <form onSubmit={handleSubmit} ref={formRef}>
          <div className='form-container'>
            <div className="form-group">
              <label htmlFor="name">Your name</label>
              <input 
                className="custom-input" 
                type="text" 
                id="name" 
                name="name" 
                value={formData.name} 
                onChange={handleChange}
                ref={inputRefs.name}
                onKeyDown={(e) => e.key === 'Enter' && inputRefs.email.current.focus()}
                required 
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Your email</label>
              <input 
                className="custom-input" 
                type="email" 
                id="email" 
                name="email" 
                value={formData.email} 
                onChange={handleChange}
                ref={inputRefs.email}
                onKeyDown={(e) => e.key === 'Enter' && inputRefs.subject.current.focus()}
                required 
              />
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="subject">Phone number</label>
            <input 
              className="custom-input" 
              type="text" 
              id="subject" 
              name="subject" 
              value={formData.subject} 
              onChange={handleChange}
              ref={inputRefs.subject}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  inputRefs.message.current.focus();
                  inputRefs.message.current.setSelectionRange(0, 0);
                }
              }}
              required 
            />
          </div>
          <div className="form-group">
            <label htmlFor="message">Message</label>
            <textarea
              className="custom-textarea"
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              ref={inputRefs.message}
              placeholder="Write here your message"
              required
            />
          </div>
          <div className="form-group">
            <label className='checkbox-label'>
              <input
                type="checkbox"
                className={`custom-checkbox ${acceptedTerms ? 'active' : 'inactive'}`}
                checked={acceptedTerms}
                onChange={handleCheckboxChange}
                required
              />
              <span className='text-check'>I agree to receive messaging from FIG at the phone number provided above. Data rates may apply, reply STOP to opt out.</span>
            </label>
          </div>
          <button className='button-modal' type="submit" disabled={!acceptedTerms}>SEND MESSAGE</button>
        </form>
        <a href="/privacy-policy" className="document-link" >
        {isMobile ?(
        <h6 className='link-policy'>PRIVACY POLICY</h6>
      ):(
        <h6 className='link-policy'>PRIVACY POLICY | TERMS & CONDITIONS</h6>
      )}
        </a>
      </div>
    </div>
  );
}

export default ModalContact;
