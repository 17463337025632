import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import 'bootstrap/dist/css/bootstrap-grid.min.css';
import '../App.css';

import FigUno from '../assets/img/Homeowners/Fig1.png';
import FigDos from '../assets/img/Homeowners/Fig2.png';
import OldUno from '../assets/img/Homeowners/old insurance1.png';
import OldDos from '../assets/img/Homeowners/old insurance2.png';
import ManoCasa from '../assets/img/Homeowners/ManoCasa.png';
import ManoCasaMobile from '../assets/img/Homeowners/ManoCasaMobile.png';
import PhoneChat from '../assets/gif/homeowners/Chat.gif';
import PhoneChatMobile from '../assets/gif/homeowners/ChatMobile.gif';
import MujerSala from '../assets/gif/homeowners/Homeowners_Ilustracion3-.gif';
import MujerSalaMobile from '../assets/gif/homeowners/Homeowners_Ilustracion3-mobile.gif';

import Navbar from '../components/Header/Navbar';
import Button from '../components/Button';
import Footer from '../components/Footer';
import FooterMobile from '../components/FooterMobile';
import Star from '../Utils/Star';
import StarMobile from '../Utils/StarMobile';
import Carousel from '../Utils/Carousel';
import CarouselMobile from '../Utils/CarouselMobile';
import ServiceHome from '../Utils/Homeowners/ServiceHome';
import ServiceHomeMobile from '../Utils/Homeowners/ServiceHomeMobile';
import QuestionsHome from '../Utils/Homeowners/QuestionsHome';
import QuestionsHomeMobile from '../Utils/Homeowners/QuestionHomeMobile';
import AnimatedImage from '../Utils/Animated/AnimatedImage';
import BoxHom from '../Utils/Homeowners/BoxHom';
import BoxMobile from '../Utils/BoxMobile';

function Homeowner() {
  const isMobileOrTablet = useMediaQuery({ query: '(max-width: 1200px)' });
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
  const isTablet = useMediaQuery({ query: '(min-width: 601px) and (max-width: 1200px)' });

  const getMaxMarginTop = () => (isMobile ? 360 : isTablet ? 450 : 750);

  const [imageMarginTop, setImageMarginTop] = useState(getMaxMarginTop());
  const [imagesLoaded, setImagesLoaded] = useState({ ManoCasa: false });

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      let newMarginTop = (isMobile ? 360 : isTablet ? 360 : 500) + scrollTop * 1;
      newMarginTop = Math.min(newMarginTop, getMaxMarginTop());
      setImageMarginTop(newMarginTop);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isMobile, isTablet]);

  const handleImageLoad = (imageName) => setImagesLoaded((prevState) => ({ ...prevState, [imageName]: true }));

  const [isOn, setIsOn] = useState(false);
  const handleToggle = () => setIsOn(!isOn);

  const sliderStyle = { backgroundColor: isOn ? '#A55FFF' : '#CED3D6' };

  return (
    <div className='App'>
      <Navbar />
      <div style={{ height: 'fit-content' }}>
        <div className='Cardhomeowner'>
          <p className='TitleHome1'>
            Protect the place <br />
            memories are made
          </p>
          <p className='SubTitleHome1'>
            No complications. Exceptional prices. Endorsed by your lender.
          </p>
          <div className='buttonHome'>
            <Button />
          </div>
          <img
            src={isMobile ? ManoCasaMobile : ManoCasa}
            className={`Parallax ImageHomeowners ${imagesLoaded.ManoCasa ? 'image-fade-in loaded' : 'image-fade-in'}`}
            style={{ marginTop: `${imageMarginTop}px` }}
            onLoad={() => handleImageLoad('ManoCasa')}
            alt='ManoCasa'
          />
        </div>
        <div className='CardHomeowner2'>
          <h1 className='TitleHome2'>Securing the top position in America</h1>
          <p className='SubTitleHome2'>FIG was voted the most loved insurance in America</p>
          <div className={isMobileOrTablet ? 'RatingMobile':'Rating'}>
          {isMobileOrTablet ? <StarMobile /> : <Star />}
          </div>
          <div className={isMobileOrTablet ? 'CarouselMobileHome':'Carousel'}>
          {isMobileOrTablet? <CarouselMobile /> : <Carousel />}
          </div>
        </div>
        <div className='CardHome3'>
          <p className='TitleHomeowner'>
            <strong>
              One less hassle when completing the closing <br />
              process for a new home.
            </strong>
          </p>
          <p className='SubTitleHomeowner3'>
            A team of home experts, along with Gazelle, our AI bot, will guide you <br />
            to the finish line quicker than you can say "Honey, we're home!"
          </p>
          <div className='ImageHomeownersChat'>
            <img src={isMobileOrTablet ? PhoneChatMobile : PhoneChat} width='100%' height='100%' alt='PhoneChat' />
          </div>
        </div>
        <div className={`CardhomeownersCheck ${isOn ? 'on' : 'off'}`}>
          <div className='CardhomeownersCheckText'>
            <h1 className='titleCheck'>The most effortless <br/> way to slide!</h1>
            <p className='SubTitleCheck'>We'll cancel your previous insurance and handle the escrow payments.</p>
          </div>
          <div className='slider-container'>
            <label>
              <img src={isOn ? FigUno : FigDos} alt={isOn ? 'FigUno' : 'FigDos'} className='FigBottom' />
            </label>
            <div className='slider' style={sliderStyle} onClick={handleToggle}>
              <div className={`slider-knob ${isOn ? 'on' : 'off'}`} />
            </div>
            <label>
              <img src={isOn ? OldDos : OldUno} alt={isOn ? 'OldUno' : 'OldDos'} className='FigTop' />
            </label>
          </div>
        </div>
        <div className='CardRenter3'>
          <h1 className='TitleHomeowner4'>Comprehensive coverage from wall to wall</h1>
          {isMobileOrTablet ? <ServiceHomeMobile /> : <div className='ServiceCar'> <ServiceHome /> </div>}
        </div>
        <div className='CardRenter4'>
          <h1 className='TitleRenter4'>Good to know...</h1>
          <p className='SubTitleRenter4'>
            Homeowners insurance can be confusing, so we worked hard to make it simple. If you still have questions, you can check out homeowners insurance explained.
          </p>
          <div className='BoxRender'>
            {isMobileOrTablet ? <QuestionsHomeMobile /> : <QuestionsHome />}
          </div>
          <p className='SubTitleRenter4_1'>Didn't find what you were looking for?</p>
          <button className='ButtonRender'>
            <p className='TextButtonRender'>VISIT OUR FAQ</p>
          </button>
        </div>
        <div className='CardHomeowner5'>
          <h1 className='TitleHomeowner4'>Affordable pricing that is budget-friendly</h1>
          <p className='SubTitleHome4'>Starting from just $25/mo.</p>
          <div className='buttonRenter2'>
            <Button />
          </div>
          <div className='ImageHomeownersWoman'>
            <img src={isMobileOrTablet? MujerSalaMobile : MujerSala} width='100%' height='100%' alt='MujerSala' />
          </div>
        </div>
        <div className='CardHome3'>
          <h1 className='TitleHomeowner5'>More products from FIG</h1>
          <p className='SubTitleHomeowner6'>
            <AnimatedImage /> Amazing savings when you bundle
          </p>
          <div className={isMobileOrTablet ? 'BoxMobile' : 'BoxHome'}>
            {isMobileOrTablet ? <BoxMobile /> : <BoxHom/>}
          </div>
        </div>
      </div>
      {isMobileOrTablet ? <FooterMobile /> : <Footer />}
    </div>
  );
}

export default Homeowner;
