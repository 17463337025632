import React,{useState} from 'react'
import '../../App.css';


import FlechaAbajo from '../../assets/img/flechaAbajo.png';
import FlechaArriba from '../../assets/img/flechaArriba.png'

function QuestionsCarMobile() {
  const questions = [
    {"Question":"How do you help me clean up CO₂?","Answer":"Reducing your carbon footprint by planting trees! Recognizing that an average car emits 4.6 metric tons of CO₂ annually, we've committed to planting trees. Our app calculates the number of trees needed based on your mileage."},
    {"Question":"Can I trust you guys?","Answer":"Certainly. Unlike many new insurance companies, FIG is an insurance carrier reinsured by some of the industry's most reputable names. We hold an A-Exceptional rating for financial stability."},
    {"Question":"Do all drivers need the FIG app on their phone?","Answer":"They do. We use ‘telematics’ to price our policies fairly, to help with fast claims, provide on-site emergency services, and to calculate your CO₂ emissions so we can plant trees in your name."},
    {"Question":"Is it okay to disable location services for the Lemonade app?","Answer":"FIG Car requires location services to operate at all times. Please keep it on so we can provide you with our full service."},
    {"Question":"Can I switch now if I’m currently insured elsewhere?","Answer":"Certainly. Once you've signed up for a FIG policy, remember to submit a cancellation request to your current carrier to ensure seamless coverage. Our team is here to assist you!"},
    {"Question":"What happens if I’m in an accident?","Answer":"After ensuring everyone's safety, file a claim through our website, a quick and straightforward process. Answer a few questions and follow the steps. We'll reimburse you for repairs or replace your car on covered claims."},
    {"Question":"I’m an Uber/Lyft driver, can I use your insurance?","Answer":"Not at the moment. Our existing coverage is only applicable to drivers who do not use their cars for business or commercial purposes. Apologies!"},
    {"Question":"I’m buying a new car, how do I insure it?","Answer":"After obtaining a FIG Car policy, adding a new car is a breeze through the app or website. If you're purchasing a new car while initiating a policy, we can offer same-day coverage, and our team is ready to assist!"},
    {"Question":"Can I edit my coverage after purchase?","Answer":"No worries. You can modify your coverages by adjusting limits and deductibles through our website whenever you choose."},
    {"Question":"What happens if someone who’s not insured hits my car?","Answer":"You're protected. Many states mandate coverage to ensure you're not stranded in a difficult situation if an uninsured driver causes an accident. Your FIG Car policy will handle the costs in such covered claims."},
    {"Question":"How do you calculate my price?","Answer":"We consider various factors to determine a precise and equitable price, taking into account your car's model, your location, and driving history. Essentially, safe, low-mileage drivers pay less."},
    {"Question":"What discounts do you have for eco-friendly cars?","Answer":"We provide discounts for electric and hybrid cars, and we also offer coverage for emergency charging and damages to you."},
]

const [expandedQuestions, setExpandedQuestions] = useState([]);

   
    const handleToggle = (index) => {
        setExpandedQuestions(prevState =>
        prevState.includes(index)
            ? prevState.filter(i => i !== index)
            : [...prevState, index]
        );
    };

    
  return (
    <div className='container'>
    {questions.map((question, index) => (
      <div key={index}>
        <div className='header' onClick={() => handleToggle(index)}>
          <span className='title'>{question.Question}</span>
          <img
            src={expandedQuestions.includes(index) ? FlechaAbajo : FlechaArriba}
            alt={expandedQuestions.includes(index) ? 'flecha hacia abajo' : 'flecha hacia arriba'}
            className='icon'
          />
        </div>
        {expandedQuestions.includes(index) && (
          <div className={`content ${expandedQuestions.includes(index) ? 'expanded' : ''}`}>
            <p className='text'>{question.Answer}</p>
          </div>
        )}
      </div>
    ))}
  </div>
  )
}




export default QuestionsCarMobile
