import React from 'react'
import '../../App.css';

import ComponentCatorce from '../../assets/icons/car/Component 14.png';
import ComponentQuince from '../../assets/icons/car/Component 15.png';
import ComponentDieciseis from '../../assets/icons/car/Component 16.png';
import ComponentDiecisiete from '../../assets/icons/car/Component 17.png';
import ComponentDieciocho from '../../assets/icons/car/Component 18.png';
import ComponentDiecinueve from '../../assets/icons/car/Component 19.png'

function ServiceCarDosMobile() {
  return (
    <div className='infoServiceCar'>
    <div className='container-service-car'>
      <div className='containerService'>
          <img src={ComponentCatorce}   className='imgIconCar'/>
          <h1 className='TitleCard'>If you're in a <br/> car accident</h1>
          <text className='TextCardRenter4'>We'll handle the expenses for <br/>damage repair or recovery.</text>
      </div>
      <div className='containerService'>
          <img src={ComponentQuince}   className='imgIconCar'/>
          <h1 className='TitleCard'>If you're stuck on  <br/> the highway</h1>
          <text className='TextCardRenter4'>We offer roadside assistance for<br/> tows, jumps, and more.</text>
      </div>
      <div className='containerService'>
          <img src={ComponentDieciseis} className='imgIconCar'/>
        <h1 className='TitleCard'>If the unexpected<br/> happens</h1>
        <text className='TextCardRenter4'>We'll protect against damage from <br/>fires, weather,vandalism and more.</text>
      </div>
      <div className='containerService'>
          <img src={ComponentDiecisiete}  className='imgIconCar'/>
        <h1 className='TitleCard'>If your glass <br/> sustains damage</h1>
        <text className='TextCard'>We'll handle repairs for <br/>windshields, moonroofs,<br/> and more.</text>
      </div>
      <div className='containerService'>
          <img src={ComponentDieciocho} className='imgIconCar'/>
        <h1 className='TitleCard'>If you face a <br/> liability lawsuit</h1>
        <text className='TextCard'>We'll assist with covering the <br/> expensesfor a lawyer <br/> to defend you.</text>
      </div>
      <div className='containerService'>
          <img src={ComponentDiecinueve} className='imgIconCar'/>
        <h1 className='TitleCard'>If your car gets stolen</h1>
        <text className='TextCard'>We'll cover the expense of <br/> replacing it with <br/> a new vehicle.</text>
      </div>
      </div>
    </div>
  )
}

export default ServiceCarDosMobile
