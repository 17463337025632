import React,{useState} from 'react';
import '../App.css'
import Modal from 'react-modal';
import ModalContact from './Modal/ModalContact';

 function ButtonTwoMobile() {
  const [isModal, setIsModal] = useState(false)

  function openModal() {
    setIsModal(true);
  }

  function closeModal() {
    setIsModal(false);
  }
  const customStyles = {
    content: {
      padding: '1rem',
      border: '0',
      borderRadius: '0.5rem',
      boxShadow: 'hsl(0 0% 0% / 10%) 0 0 0.5rem 0.25rem',
      position: 'relative',
      transform: 'translate(-50%, -50%)',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      backgroundColor: '#F2F5F9', // Cambiar el color de fondo del modal
      zIndex: '1000', // Agregado zIndex para sobreponer el modal
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: '999',
    },
  };
  return (
    <>
      <button className='ButtonPrin' onClick={openModal}>
        <p className='TextButtonSecond'>CONTACT US AND SWITCH</p>
      </button>
      <Modal
        isOpen={isModal}
        onRequestClose={closeModal}
        style={customStyles}
        >       
        <ModalContact/>
      </Modal>
    </>
  )
}
const styles = {
  ButtonPrin: {
    width: '280px',
    height: '60%',
    margin: '9% 0px 4% 5%',
    backgroundColor: '#6937F4',
    color: 'white',
    fontSize: '20px',
    textAlign: 'center',
    borderRadius: '10px',
    cursor: 'pointer',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: '#6937F4',
    boxShadow: '5px 10px 15px -6px #6937F4',
  },
  ButtonPrinHover: {
    backgroundColor: '#4f29b9',
  },
  TextButtonPrin: {
    fontSize: '13px',
    margin: '12px 0px'
  },
};
export default ButtonTwoMobile;