import React, { useRef, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Slider.css';

import ImageUno from '../../assets/gif/life/Animacion1-Life.mp4';
import ImageDos from '../../assets/gif/life/Animacion2_life.mp4';
import ImageTres from '../../assets/gif/life/Animacion3_Life.mp4';
import ImageCuatro from '../../assets/gif/life/Animacion4_Life.mp4';

function ImageSlider({ isSliderPlaying }) {
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
  const isTablet = useMediaQuery({ query: '(min-width: 601px) and (max-width: 1200px)' });
  const isDesktop = !isMobile && !isTablet;

  const gifs = [
    { src: ImageUno, autoplaySpeed: 2000 },
    { src: ImageDos, autoplaySpeed: 50000 },
    { src: ImageTres, autoplaySpeed: 7000 },
    { src: ImageCuatro, autoplaySpeed: 7000 },
  ];

  const sliderRef = useRef(null);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [isSliderVisible, setIsSliderVisible] = useState(false);

  const handleOnEnded = () => {
    setCurrentSlideIndex((prevIndex) => (prevIndex + 1) % gifs.length);
  };

  const handleBeforeChange = (oldIndex) => {
    const prevGifElement = sliderRef.current.innerSlider.list.querySelector(`.slick-slide[data-index="${oldIndex}"] video`);
    if (prevGifElement) {
      prevGifElement.pause();
      prevGifElement.currentTime = 0;
    }
  };

  const handleVisibilityCheck = () => {
    const sliderElement = sliderRef.current && sliderRef.current.innerSlider.list;   
    if (sliderElement) {
      const rect = sliderElement.getBoundingClientRect();
      const isVisible = rect.top < window.innerHeight && rect.bottom >= 0;
      setIsSliderVisible(isVisible);
    }
  };

  useEffect(() => {
    const scrollHandler = () => {
      handleVisibilityCheck();
    };

    window.addEventListener('scroll', scrollHandler);

    return () => {
      window.removeEventListener('scroll', scrollHandler);
    };
  }, []);

  useEffect(() => {
    sliderRef.current.slickGoTo(currentSlideIndex, true);
  }, [currentSlideIndex, isDesktop]);

  useEffect(() => {
    if ((isMobile || isTablet) && isSliderVisible) {
      const currentGifElement = sliderRef.current.innerSlider.list.querySelector(`.slick-slide[data-index="${currentSlideIndex}"] video`);
      if (currentGifElement) {
        currentGifElement.play();
      }
    }
  }, [currentSlideIndex, isSliderVisible, isMobile, isTablet]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    beforeChange: handleBeforeChange,
    afterChange: (index) => {
      const currentGifElement = sliderRef.current.innerSlider.list.querySelector(`.slick-slide[data-index="${index}"] video`);
      if (currentGifElement && isSliderVisible){
        currentGifElement.play();
      }
    },
  };

  const renderGif = (gif, index) => {
    return (
      <div key={index} data-index={index}>
        <video
          src={gif.src}
          style={{ width: '100%', objectFit: 'cover', outline: 'none' }}
          onEnded={handleOnEnded}
          playsInline
          muted
          onMouseEnter={() => {
            if (index === 0 && isSliderVisible) {
              sliderRef.current.innerSlider.list.querySelector(`.slick-slide[data-index="0"] video`).play();
            }else if ((isMobile || isTablet) && isSliderVisible && index === currentSlideIndex) {
              sliderRef.current.innerSlider.list.querySelector(`.slick-slide[data-index="${index}"] video`).play();
            }

          }}
          onCanPlay={() => {
            if (index === 0 && isSliderVisible) {
              sliderRef.current.innerSlider.list.querySelector(`.slick-slide[data-index="0"] video`).play();
            }else if ((isMobile || isTablet) && isSliderVisible && index === currentSlideIndex) {
              sliderRef.current.innerSlider.list.querySelector(`.slick-slide[data-index="${index}"] video`).play();
            }

          }}
          onWaiting={(e) => {
            console.log('El video está esperando...');
          }}
        />
      </div>
    );
  };

  return (
    <Slider ref={sliderRef} {...settings}>
      {gifs.map(renderGif)}
    </Slider>     
  )}

export default ImageSlider;
