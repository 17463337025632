import React, { useState } from 'react';
import Modal from 'react-modal';
import ModalContact from '../components/Modal/ModalContact';

const DescriptionMobile = ({item}) => {
  const [isModal, setIsModal] = useState(false);


  function openModal() {
    setIsModal(true);
  }

  function closeModal() {
    setIsModal(false);
  }

  const customStyles = {
    content: {
      padding: '1rem',
      border: '0',
      borderRadius: '0.5rem',
      boxShadow: 'hsl(0 0% 0% / 10%) 0 0 0.5rem 0.25rem',
      position: 'relative',
      transform: 'translate(-50%, -50%)',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      backgroundColor: '#F2F5F9',
      zIndex: '1000',
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: '999',
    },
  };

  return (
    <div className='BoxContainer'>
      <img src={item.Image} width={'70%'} height={'70%'} style={{marginTop:'7%'}}/>
      <br/>
      <text className='titleBox'>{item.Servicie}</text>
      <div className='button'>
        <a>
          <button className='ButtonBox' onClick={openModal}>
            <p className='TextButtonBox'>CONTACT US</p>
          </button>
        </a>
        <Modal
        isOpen={isModal}
        onRequestClose={closeModal}
        style={customStyles}
      >
        <ModalContact />
      </Modal>
        <text className='subtext' >{item.Price}</text>
      </div>
    </div>
  )
}


export default DescriptionMobile;
