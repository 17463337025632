import React, { useState } from 'react';
import Modal from 'react-modal';
import { FaBars, FaTimes} from 'react-icons/fa'

import ModalContact from './Modal/ModalContact';
import IconClose from '../assets/icons/x.png'


function Button() {
  const [isModal, setIsModal] = useState(false);

  function openModal() {
    setIsModal(true);
  }

  function closeModal() {
    setIsModal(false);
  }

  const customStyles = {
    content: {
      padding: '1rem',
      border: '0',
      borderRadius: '0.5rem',
      boxShadow: 'hsl(0 0% 0% / 10%) 0 0 0.5rem 0.25rem',
      position: 'relative',
      transform: 'translate(-50%, -50%)',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      backgroundColor: '#F2F5F9',
      zIndex: '1000',
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: '999',
    },
  };

  return (
    <>
      <button className='ButtonPrin' onClick={openModal}>
        <p style={styles.TextButtonPrin}>CONTACT US</p>
      </button>
      <Modal
        isOpen={isModal}
        onRequestClose={closeModal}
        style={customStyles}
      >
        <ModalContact />
        <FaTimes className='IconCloseModal' onClick={closeModal}/> 
      </Modal>
    </>
  );
}

const styles = {
  TextButtonPrin: {
    fontSize: '20px',
    marginBlock: '5%',
  },
};

export default Button;
