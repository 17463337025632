import React from 'react'
import '../../App.css';

import ComponentCatorce from '../../assets/icons/car/Component 14.png';
import ComponentQuince from '../../assets/icons/car/Component 15.png';
import ComponentDieciseis from '../../assets/icons/car/Component 16.png';
import ComponentDiecisiete from '../../assets/icons/car/Component 17.png';
import ComponentDieciocho from '../../assets/icons/car/Component 18.png';
import ComponentDiecinueve from '../../assets/icons/car/Component 19.png'

function ServiceCarDos() {
  return (
    <>
    <div className='InfoCardHomeowner'>
      <div className='containerCard'>
        <div className='imgBordIcon1'>
          <img src={ComponentCatorce}   className='imgIcon6_1'/>
        </div>        
        <h1 className='TitleCard'>If you're in a <br/>car accident</h1>
        <text className='TextCardRenter4'>We'll handle the expenses for <br/> damage repair or recovery.</text>
      </div>
      <div className='containerCard'>
        <div className='imgBordIcon1'>
          <img src={ComponentQuince}   className='imgIcon6_1'/>
        </div>        
        <h1 className='TitleCard'>If you're stuck on <br/>the highway</h1>
        <text className='TextCardRenter4'>We offer roadside assistance for <br/> tows, jumps, and more.</text>
      </div>
      <div className='containerCard'>
        <div className='imgBordIcon1'>
          <img src={ComponentDieciseis} className='imgIcon6_1'/>
        </div>        
        <h1 className='TitleCard'>If the <br/>unexpected  happens</h1>
        <text className='TextCard'>We'll protect against damage <br/>from fires, weather, <br/>vandalism and  more.</text>
      </div>
    </div>
    <div className='InfoCardHomeowner'>
      <div className='containerCard'>
        <div className='imgBordIcon1'>
          <img src={ComponentDiecisiete}  className='imgIcon6_1'/>
        </div>        
        <h1 className='TitleCard'>If your glass <br/>sustains damage</h1>
        <text className='TextCard'>We'll handle repairs for <br/> windshields, moonroofs, and <br/>more.</text>
      </div>
      <div className='containerCard'>
        <div className='imgBordIcon1'>
          <img src={ComponentDieciocho} className='imgIcon6_1'/>
        </div>        
        <h1 className='TitleCard'>If you face a <br/> liability lawsuit</h1>
        <text className='TextCard'>We'll assist with covering the <br/> expenses for a lawyer to defend <br/>you.</text>
      </div>
      <div className='containerCard'>
        <div className='imgBordIcon1'>
          <img src={ComponentDiecinueve} className='imgIcon6_1'/>
        </div>        
        <h1 className='TitleCard1'>If your car gets stolen</h1>
        <text className='TextCard3_1'>We'll cover the expense of <br/> replacing it with a new vehicle.</text>
      </div>
    </div>
    </>
  )
}

export default ServiceCarDos
