import React, { useState, useEffect } from 'react';
import '@fontsource/open-sans';
import '../App.css';
import { useMediaQuery } from 'react-responsive';
import Woman from '../assets/gif/renters/Renters_Illustration1_General.gif';
import ImgGirl from '../assets/img/Render/RentersGirl.png';
import WomanMobile from '../assets/gif/renters/Renters_Illustration1_General-2-Mobile.gif';
import ImgCompu from '../assets/img/Render/RentersCompu.png';
import ImgShoes from '../assets/img/Render/RentersShoes.png';
import ImgTablet from '../assets/img/Render/RentersTablet.png';
import ImgSkate from '../assets/img/Render/RentersSkate.png';
import ImgLip from '../assets/img/Render/RentersLip.png';
import ImgPlay from '../assets/img/Render/RentersPlay.png';

import Navbar from '../components/Header/Navbar';
import Footer from '../components/Footer';
import FooterMobile from '../components/FooterMobile';
import Button from '../components/Button';

import AnimatedImage from '../Utils/Animated/AnimatedImage';
import Star from '../Utils/Star';
import Box from '../Utils/Box';
import Carousel from '../Utils/Carousel';
import ServiceRenders from '../Utils/Renders/ServiceRenders';
import ServiceRentersMobile from '../Utils/Renders/ServiceRentersMobile';
import QuestionsRender from '../Utils/Renders/QuestionsRender';
import QuestionMobile from '../Utils/Renders/QuestionMobile';
import StarMobile from '../Utils/StarMobile';
import CarouselMobile from '../Utils/CarouselMobile';
import BoxMobile from '../Utils/BoxMobile';

function Render() {
  const isMobileOrTablet = useMediaQuery({ query: '(max-width: 1200px)' });
  const [marginTop, setMarginTop] = useState(0);
  const maxMarginTop = 80;
  const [imagesLoaded, setImagesLoaded] = useState({});

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      let newMarginTop = 5 + scrollTop * 5;

      if (newMarginTop > maxMarginTop) {
        newMarginTop = maxMarginTop;
      }
      setMarginTop(newMarginTop);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleImageLoad = (imageName) => {
    setImagesLoaded((prevState) => ({
      ...prevState,
      [imageName]: true,
    }));
  };

  const renderImage = (src, alt, imageName) => (
    <img
      src={src}
      style={{ marginTop: isMobileOrTablet ? '4px' : `${marginTop}px` }}
      className={`Parallax ${imageName} ${imagesLoaded[imageName] ? 'image-fade-in loaded' : 'image-fade-in'}`}
      onLoad={() => handleImageLoad(imageName)}
      alt={alt}
    />
  );

  const renderContentForDevice = (mobileContent, desktopContent) =>
    isMobileOrTablet ? mobileContent : desktopContent;

  return (
    <div className='App'>
      <Navbar />
      {renderImage(ImgGirl, 'ImgGirl', 'ImgGirl')}
      {renderImage(ImgCompu, 'ImgCompu', 'ImgCompu')}
      {renderImage(ImgShoes, 'ImgShoes', 'ImgShoes')}
      {renderImage(ImgTablet, 'ImgTablet', 'ImgTablet')}

      <div className='scroll'>
        <div className='CardRenter'>
          <p className='TitleHome1'>Renters insurance that keeps your stuff safe</p>
          <p className='SubTitleHome1'>Trusted by Renters. Approved by Landlords. Plans from $5/mo.</p>
          <div className='buttonHome'>
            <Button />
          </div>
        </div>

        <div className='CardHomeowner2'>
          <h1 className='TitleRenter6'>Renters insurance cherished by most in America</h1>
          <p className='SubTitleHome2'>
            FIG boasts a stellar 4.9-star rating on the App Store and has received top ratings from other reputable sources.
          </p>
          <div className={isMobileOrTablet ? 'RatingMobile' : 'Rating'}>
            {isMobileOrTablet ? <StarMobile /> : <Star />}
          </div>
          {!isMobileOrTablet && (
            <>
              {renderImage(ImgPlay, 'ImgPlay', 'ImgPlay')}
              {renderImage(ImgSkate, 'ImgSkate', 'ImgSkate')}
              {renderImage(ImgLip, 'ImgLip', 'ImgLip')}
            </>
          )}
          <div className={isMobileOrTablet ? 'CarouselMobileRenter' : 'Carousel'}>
            {isMobileOrTablet ? <CarouselMobile /> : <Carousel />}
          </div>
        </div>

        <div className='CardRenter3'>
          <h1 className='TitleRenter3'>What exactly does renters insurance cover?</h1>
          <p className='SubTitleRenter3'>
            It safeguards your personal belongings and assists in covering costs related to personal liability. FIG Renters is there for you in times of misfortune.
          </p>
          <>
            {isMobileOrTablet ? <ServiceRentersMobile /> : <ServiceRenders />}
          </>
        </div>

        <div className='CardRenter4'>
          <h1 className='TitleRenter4'>Good to know...</h1>
          <p className='SubTitleRenter4'>
            Renters insurance might seem complex, but we've put in the effort to simplify it.
            For any lingering questions, refer to our guide on understanding renters insurance.
          </p>
          <div className='BoxRender'>
            {isMobileOrTablet ? <QuestionMobile /> : <QuestionsRender />}
          </div>
          <p className='SubTitleRenter4_1'>Didn't find what you were looking for?</p>
          <button className='ButtonRender'>
            <p className='TextButtonRender'>VISIT OUR FAQ</p>
          </button>
        </div>

        <div className='CardRenter5'>
          <h1 className='TitleHome4'>Starting from $5/mo.</h1>
          <p className='SubTitleHome4'>Protect all your belongings with just a few taps.</p>
          <div className='buttonRenter2'>
            <Button />
          </div>
          <div className='RenderWoman'>
            <img src={isMobileOrTablet ? WomanMobile : Woman} width={'100%'} height={'100%'} alt="Woman" />
          </div>
          <h1 className='TitleRenderWoman'>Secure your belongings and champion what holds significance to you.</h1>
        </div>

        <div className='CardRenter3'>
          <h1 className='TitleRenter6'>More products from FIG</h1>
          <p className='SubTitleRenter6'>
            <AnimatedImage /> Incredible savings when you bundle
          </p>
          <div className={isMobileOrTablet ? 'BoxMobile' : 'Box'}>
            {isMobileOrTablet ? <BoxMobile /> : <Box />}
          </div>
        </div>
      </div>
      <div>
        {isMobileOrTablet ? <FooterMobile /> : <Footer />}
      </div>
    </div>
  );
}

export default Render;
