import React from 'react'
import { FaBars, FaTimes} from 'react-icons/fa'
import styled from 'styled-components'

function BurguerButton(props) {
  return (
    <Burguer clicked={props.clicked}>
      <div onClick={props.handleClick}>
        <span></span>
        {props.clicked ? <FaTimes className='Close-icon' /> : <FaBars className='Hamburger-icon' />}
      </div>
    </Burguer>
  )
}

export default BurguerButton

const Burguer = styled.div`
  .Hamburger-icon {
    color: #595656;
    font-size: 30px;
    margin-right: 5px;
    margin-top: 5px;
    position: relative; 
  }

  .Close-icon {
    position: absolute;
    top: 10px;
    transition: opacity 5s ease, transform 5s ease; /* Ajusta la duración de la transición */
    left: ${props => (props.clicked ? '47%' : '-100%')}; /* Ajusta la posición inicial */
    z-index: 1;
    color: #ADB5BD; /* Cambia el color del icono según tus preferencias */
    font-size: 20px; /* Cambia el tamaño del icono según tus preferencias */
    opacity: ${props => (props.clicked ? 1 : 0)}; /* Hace que el icono de la X aparezca o desaparezca */
  }
`;
