import React,{useState} from 'react'
import '../../App.css';

import FlechaAbajo from '../../assets/img/flechaAbajo.png';
import FlechaArriba from '../../assets/img/flechaArriba.png'


function QuestionsHomeMobile() {
  const questions = [
    {"Question":"What does FIG Homeowners insurance cover?","Answer":"Your policy safeguards your home and property from damage caused by events like wildfires, severe weather, crime, and vandalism. Additionally, it provides protection against legal claims for damage or injury inadvertently caused to others. Explore further details on coverage here."},
    {"Question":"How can I switch to FIG?","Answer":"Once you inform us about your switch from another provider, we'll inform your lender and manage the necessary updates to your escrow payments. Subsequently, we'll communicate your switch request to your previous provider."},
    {"Question":"Can I trust you guys?","Answer":"Certainly. Unlike many new insurance companies, FIG is an insurance carrier reinsured by some of the industry's most reputable names. We hold an A-Exceptional rating for financial stability."},
    {"Question":"How much does it cost?","Answer":"Homeowners insurance begins at $25 per month, but it fluctuates by state and is influenced by factors such as your home's age, rebuilding costs, building materials, and the coverage and additional options you select."},
    {"Question":"How do I file a claim?","Answer":"Very easily: just contact us and ask for  ‘Claims’ . We’ll guide you through the process from there."},
    {"Question":"How do I get paid for my claim?","Answer":"Following the completion of a claim report through the FIG website, you'll be prompted to input your bank account details. Upon approval of your claim, we'll directly transfer the payment (minus your deductible) into your account."},
    {"Question":"What if I want to cancel my policy?","Answer":"Feel free to cancel your policy anytime using the FIG website, and you'll receive a refund for the remaining paid period. No hard feelings!"},
    {"Question":"What kind of payments do you accept?","Answer":"We can handle everything through your lender or bank (escrow), or you can make direct payments with your credit card."},
    {"Question":"Can I make changes to my policy after I purchase it?","Answer":"Certainly! After acquiring your policy, just launch the FIG website and log into your account here. Choose your policy, adjust coverage amounts according to your requirements, or include additional coverage from the Add-ons section."},
  ]
   
  const [expandedQuestions, setExpandedQuestions] = useState([]);

  const handleToggle = (index) => {
    setExpandedQuestions(prevState =>
      prevState.includes(index)
        ? prevState.filter(i => i !== index)
        : [...prevState, index]
    );
  };

  return (
  <div className='container'>
      {questions.map((question, index) => (
        <div key={index}>
          <div className='header' onClick={() => handleToggle(index)}>
            <span className='title'>{question.Question}</span>
            <img
              src={expandedQuestions.includes(index) ? FlechaAbajo : FlechaArriba}
              alt={expandedQuestions.includes(index) ? 'flecha hacia abajo' : 'flecha hacia arriba'}
              className='icon'
            />
          </div>
          {expandedQuestions.includes(index) && (
            <div className={`content ${expandedQuestions.includes(index) ? 'expanded' : ''}`}>
              <p className='text'>{question.Answer}</p>
            </div>
          )}
        </div>
      ))}
    </div>
  )
}

export default QuestionsHomeMobile
