import React, { useEffect, useState } from 'react';
import '@fontsource/open-sans';
import '../App.css';
import { useMediaQuery } from 'react-responsive';

import Button from '../components/Button';
import ButtonTwo from '../components/ButtonTwo';
import ButtonTwoMobile from '../components/ButtonTwoMobile';
import Star from '../Utils/Star';
import StarMobile from '../Utils/StarMobile';
import Box from '../Utils/Box';
import BoxMobile from '../Utils/BoxMobile';
import Footer from '../components/Footer';
import FooterMobile from '../components/FooterMobile';
import Carousel from '../Utils/Carousel';
import CarouselMobile from '../Utils/CarouselMobile';

import Chat from '../assets/img/home/Capa_2.png';
import ChatMobile from '../assets/img/home/Capa_2Mobile.png';
import Info from '../assets/img/home/Group 30.png';
import InfoMobile from '../assets/img/home/Group 30Mobile.png';

import AnimatedImage from '../Utils/Animated/AnimatedImage';
import Navbar from '../components/Header/Navbar';

function Home() {
  const isMobileOrTablet = useMediaQuery({ query: '(max-width: 1200px)' });

  return (
    <div className='App'>
      <Navbar />
      <div className='scroll'>
        <div className='CardHome'>
          <div className='CardHomeText'>
            <p className='TitleHome1'>Personalized Insurance <br />Using the Power of Tech</p>
            <p className='SubTitleHome1'>Immediate everything. Affordable rates. Compassionate at heart.</p>
            <div className='buttonHome'>
              <Button />
            </div>
          </div>
        </div>
        <div className='CardHome2'>
          {isMobileOrTablet ? (
            <>
              <p className='TitleHome4'><strong>The 5 Star Insurance Company</strong></p>
              <p className='SubTitleHome2'>FIG is a top-rated company and is loved by its clients</p>
              <div className='RatingMobile'>
                <StarMobile />
              </div>
            </>
          ) : (
            <>
              <p className='TitleHome2'><strong>The 5 Star <br /> Insurance Company</strong></p>
              <p className='SubTitleHome2'>FIG is a top-rated company and is loved by its clients</p>
              <div className='Rating'>
                <Star />
              </div>
            </>
          )}
          <div className={isMobileOrTablet ? 'CarouselMobileCar' : 'Carousel'}>
            {isMobileOrTablet ? <CarouselMobile /> : <Carousel />}
          </div>
        </div>
        <div className='CardHome3'>
          <p className='TitleHome3'><strong>Incredible Prices. Monthly Subscription.<br /> Bundle Discounts.</strong></p>
          <p className='SubTitleHome3'><AnimatedImage /> Amazing savings when you bundle</p>
          <div className={isMobileOrTablet ? 'BoxMobile' : 'Box'}>
            {isMobileOrTablet ? <BoxMobile /> : <Box />}
          </div>
        </div>
        <div className='CardHome4'>
          <h1 className='TitleHome4'>Instant Everything</h1>
          <p className='SubTitleHome4'>Gazelle, our delightful artificial intelligence bot, will tailor the ideal insurance for you. It's quick and effortless.</p>
          <div className='ImageChat'>
            <img src={isMobileOrTablet ? ChatMobile : Chat} width='100%' height='100%' alt="Chat" />
          </div>
        </div>
        <div className='CardHome5'>
          <h1 className='TitleHome5'>Already insured? We’ll help you switch!</h1>
          <p className='SubTitleHome5'>People left these insurance companies to join FIG:</p>
          <div className='ImageInfo'>
            <img src={isMobileOrTablet? InfoMobile : Info} width={isMobileOrTablet ? '100%' : '80%'} height='100%' alt="Info" />
          </div>
          <div className='buttonHomeDos'>
          {isMobileOrTablet ? <ButtonTwoMobile /> : <ButtonTwo />}    
          </div>
          </div>
      </div>
      <div>
        {isMobileOrTablet ? <FooterMobile /> : <Footer />}
      </div>
    </div>
  );
}

export default Home;
