import React from 'react'
import '../../App.css';

import ComponentVeinte from '../../assets/icons/car/Component 20.png';
import ComponentVeintiUno from '../../assets/icons/car/Component 21.png';
import ComponentVeintiDos from '../../assets/icons/car/Component 22.png';

function ServiceCarTres() {
  return (
    <>
    <div className='InfoCardHomeowner'>
      <div className='containerCard'>
        <div className='imgBordIcon'>
          <img src={ComponentVeinte}   className='imgIcon5_1'/>
        </div>        
        <h1 className='TitleCard1'>Emergency charging</h1>
        <text className='TextCard'>If you find yourself with a depleted <br/>battery, we'll dispatch a charging  <br/> truck to your location.</text>
      </div>
      <div className='containerCard'>
        <div className='imgBordIcon'>
          <img src={ComponentVeintiUno}   className='imgIcon5'/>
        </div>        
        <h1 className='TitleCard1'>EV/Hybrid discounts</h1>
        <text className='TextCard'>An additional discount as a token  <br/> of appreciation for eco-conscious  <br/> drivers.</text>
      </div>
      <div className='containerCard'>
        <div className='imgBordIcon'>
          <img src={ComponentVeintiDos}   className='imgIcon5'/>
        </div>        
        <h1 className='TitleCard1'>Charger coverage</h1>
        <text className='TextCard'>If anything occurs to your wall or  <br/> portable charger, we'll assist in its  <br/>replacement.</text>
      </div>
    </div>
    </>
  )
}

export default ServiceCarTres
