import React from 'react'
import '../../App.css';

import Component26 from '../../assets/icons/pet/wellnes exam.png';
import Component27 from '../../assets/icons/pet/parasite test.png';
import Component28 from '../../assets/icons/pet/Test.png';
import Component29 from '../../assets/icons/pet/Bloodwork.png';
import Component30 from '../../assets/icons/pet/vaccines.png';
import Component31 from '../../assets/icons/pet/Routine dental.png';
import Component32 from '../../assets/icons/pet/Medication_1.png';
import Component33 from '../../assets/icons/pet/Medical advice.png';

function ServicePetDosMobile() {
  return (
    <>
    <div className='InfoCard'>
      <div className='containerCard'>
        <div className='imgBordIcon1'>
          <img src={Component26} className='imgIcon'/>
        </div>  
        <div className='ContainerText'>     
        <h1 className='TitleCard'>WELLNESS EXAM</h1>
        <text className='TextCard'>Annual physical checkup for your pet</text>
        </div>
      </div>
      <div className='containerCard'>
        <div className='imgBordIcon1'>
          <img src={Component27} className='imgIcon'/>
        </div>  
        <div className='ContainerText'>      
        <h1 className='TitleCard'>PARASITE TEST</h1>
        <text className='TextCard'>Annual parasite evaluation test</text>
        </div>
      </div>
      <div className='containerCard'>
        <div className='imgBordIcon1'>
           <img src={Component28} className='imgIcon'/>
        </div>  
        <div className='ContainerText'>     
        <h1 className='TitleCard'>HEARTWORM TEST</h1>
        <text className='TextCard'>Annual screening of heartworm  <br/> infestation</text>
        </div>
      </div>
      <div className='containerCard'>
        <div className='imgBordIcon1'>
          <img src={Component29} className='imgIcon'/>
        </div>  
        <div className='ContainerText'>      
        <h1 className='TitleCard'>BLOODWORK</h1>
        <text className='TextCard'>Annual detection of diseases before <br/> symptoms appear</text>
        </div>
      </div>
      <div className='containerCard'>
        <div className='imgBordIcon1'>
          <img src={Component30} className='imgIcon'/>
        </div> 
        <div className='ContainerText'>       
        <h1 className='TitleCard'>VACCINES</h1>
        <text className='TextCard'>Annual physical checkup <br/> for your  pet</text>
        </div>
      </div>
      <div className='containerCard'>
        <div className='imgBordIcon1'>
          <img src={Component31} className='imgIcon'/>
        </div>  
        <div className='ContainerText'>      
        <h1 className='TitleCard'>ROUTINE DENTAL <br/> CLEANING</h1>
        <text className='TextCard'>Annual parasite evaluation test</text>
        </div>
      </div>
      <div className='containerCard'>
        <div className='imgBordIcon1'>
          <img src={Component32} className='imgIcon'/>
        </div>  
        <div className='ContainerText'>      
        <h1 className='TitleCard'>TICK OR HEARTWORM MEDICATION</h1>
        <text className='TextCard'>Annual screening of heartworm <br/>infestation.</text>
        </div>
      </div>
      <div className='containerCard'>
        <div className='imgBordIcon1'>
          <img src={Component33} className='imgIcon'/>
        </div>  
        <div className='ContainerText'>      
        <h1 className='TitleCard'>MEDICAL ADVICE CHAT</h1>
        <text className='TextCard'>Annual detection of diseases before <br/>symptoms appear</text>
        </div>
      </div>
      </div>
    </>
  )
}

export default ServicePetDosMobile
