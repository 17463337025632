import React from "react";
import styled from 'styled-components'
import Facebook from '../assets/icons/home/Icono9.png';
import Instagram from '../assets/icons/home/Icono8.png';
import Twiter from '../assets/icons/home/Icono10.png';
import Phone from '../assets/icons/home/Icono11.png';
import Email from '../assets/icons/home/Icono12.png';
import Logo from '../assets/img/LogoWhite.png'

function Footer(){
return (
<>
	<Box>
	<Container>
		<Row>
		<Column>
    <FooterLink href='/'>
			<Heading><img src={Logo} width={'50%'} height={'80%'}></img></Heading>
    </FooterLink>
		</Column>
		<Column>
			<Heading>FEATURE</Heading>
			<FooterLink href='/renters'>Renters</FooterLink>
			<FooterLink href="/homeowners">Homeowners</FooterLink>
			<FooterLink href="/car">Car</FooterLink>
			<FooterLink href="/life">Life</FooterLink>
			<FooterLink href="/pet">Pet</FooterLink>
			<FooterLink href="#">Accident</FooterLink>
		</Column>
		<Column>
			<Heading>COMPANY</Heading>
			<FooterLink href="#">Join the team</FooterLink>
      <FooterLink href="/privacy-policy" >Privacy Policy</FooterLink>
      <br/><br/>
		</Column>
		<Column>
			<Heading>GET OUR APP</Heading>
			<div>
			</div>
        <br></br>
        <Heading>FOLLOW US</Heading>
      <div>
        <FooterLink href="#">
          <img src={Facebook} width={'43px'} height={'40px'} className="IconsFacebook"/>
        </FooterLink>
        <FooterLink href="#">
          <img src={Instagram} width={'43px'} height={'40px'}  className="IconsInstagram"/>
        </FooterLink>
        <FooterLink href="#">
          <img src={Twiter} width={'43px'} height={'40px'} className="IconsTwiter"/>
        </FooterLink>
      </div>
	  	<br></br>
			<Heading>CONTACT US</Heading>
		<div>
      <p><img src={Email} width={'10%'} height={'60%'} className="IconosSend"/>help@fig.biz</p>
      <p><img src={Phone} width={'10%'} height={'60%'} className="IconosSend"/>+1 415 851 5007</p>
    </div>
		</Column>
		</Row>
	</Container>
	<br></br>
	<div style={{marginLeft: '8%'}}>
		<p>FIG, is an American insurance company. The company offers renters insurance, homeowners insurance, car  insurance, pet insurance, and term life insurance 
    in the United States. <br/>The company is based in Los Angeles.
    </p>
	</div>
    
	</Box>

</>
)}

export default Footer

const Box = styled.div`
padding: 8vw 1vw;
background: #454242;
bottom: 0;

font-size:5px;
flex-direction:row
@media (max-width: 1000px) {
	padding: 10px 30px;
}
img {
  justify-content:space-between;
}
text{
  color:white;
  width: 50%;
  font-size:1vw;
  font-family: "Open Sans";
}
p{
  font-size:1vw;
  display: inline-block;
  color:white; 
  margin-top: 0;
  width: 100%;
}
@media (max-width: 500px) {
  p{
    font-size:1vw;
    display: inline-block;
    color:white; 
    margin-top: 0;
    width: 100%;
  }
}
`;
const Container = styled.div`
	max-width: 80%;
	margin: 0 auto;
  align-items: center ;
`
const Column = styled.div`
display: flex;
flex-direction: column;
text-align: left;
font-size:1vw;
@media (max-width: 500px) {
  display: flex;
  flex-direction: column;
  text-align: left;
  font-size:1.2vw;
}
`;
const Row = styled.div`
display: grid;
margin-left: 3%;
grid-template-columns: repeat(4, 23.5%);;
            grid-gap: 1em;            

@media (max-width: 500px) {
  justify-content: center;
	grid-template-columns: repeat(2, 50%);;
}
`;
const FooterLink = styled.a`
color: #fff;
margin-bottom: 1vw;
font-size: 1.1vw;
text-decoration: none;

&:hover {
	color: #6937F4;
	transition: 200ms ease-in;
}
@media (max-width: 500px) {
  color: #fff;
  margin-bottom: 1vw;
  font-size: 1.2vw;
  text-decoration: none;
  
  &:hover {
    color: #6937F4;
    transition: 200ms ease-in;
  }
}
`;
 const Heading = styled.p`
font-size: 1.2vw;
color: #fff;
font-weight: bold;
margin-top: 0;
width:100%;
@media (max-width: 500px) {
  width:100%;
  font-size: 1vw;
  color: #fff;
  font-weight: bold;
  margin-top: 0;
}
`;

