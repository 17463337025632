import React,{useState} from 'react';
import '../App.css'
import Modal from 'react-modal';
import ModalContact from './Modal/ModalContact';

 function ButtonTwo() {
  const [isModal, setIsModal] = useState(false)

  function openModal() {
    setIsModal(true);
  }

  function closeModal() {
    setIsModal(false);
  }
  const customStyles = {
    content: {
      padding: '1rem',
      border: '0',
      borderRadius: '0.5rem',
      boxShadow: 'hsl(0 0% 0% / 10%) 0 0 0.5rem 0.25rem',
      position: 'relative',
      transform: 'translate(-50%, -50%)',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      backgroundColor: '#F2F5F9', // Cambiar el color de fondo del modal
      zIndex: '1000', // Agregado zIndex para sobreponer el modal
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: '999',
    },
  };

  return (
    <>
      <button className='ButtonSecond' onClick={openModal}>
        <p className='TextButtonSecond'>CONTACT US AND SWITCH</p>
      </button>
      <Modal
        isOpen={isModal}
        onRequestClose={closeModal}
        style={customStyles}
        >       
        <ModalContact/>
      </Modal>
    </>
  )
}
export default ButtonTwo;