import React from 'react';
import { useMediaQuery } from 'react-responsive';
import '../App.css';

import Pig from '../assets/img/car/Iluscar2.png';
import PigMobile from '../assets/img/car/Cariluscerdo-mobile.png';
import CarHome from '../assets/img/car/Iluscar3.png';
import CarRenter from '../assets/img/car/Iluscar4.png';
import CarPet from '../assets/img/car/Iluscar5.png';
import CarLife from '../assets/img/car/Iluscar6.png';
import Casa from '../assets/img/car/CarIllustration final.png';
import CasaMobile from '../assets/img/car/car-iluscasa-mobile.png';
import CarInicial from '../assets/gif/car/Ilustracion1Car_vr3_car.gif';
import CarInicialMobile from '../assets/gif/car/Ilustracion1Car-mobile.gif';

import Button from '../components/Button';
import Navbar from '../components/Header/Navbar';
import Footer from '../components/Footer';
import FooterMobile from '../components/FooterMobile';

import Star from '../Utils/Star';
import Carousel from '../Utils/Carousel';
import StarMobile from '../Utils/StarMobile';
import CarouselMobile from '../Utils/CarouselMobile';
import ServiceCar from '../Utils/Car/ServiceCar';
import ServiceCarDos from '../Utils/Car/ServiceCarDos';
import ServiceCarTres from '../Utils/Car/ServiceCarTres';
import QuestionsCar from '../Utils/Car/QuestionsCar';
import ServiceCarMobile from '../Utils/Car/ServiceCarMobile';
import ServiceCarDosMobile from '../Utils/Car/ServiceCarDosMobile';
import ServiceCarTresMobile from '../Utils/Car/ServiceCarTresMobile';
import QuestionsCarMobile from '../Utils/Car/QuestionCarMobile';

function Car() {
  const isMobileOrTablet = useMediaQuery({ query: '(max-width: 1200px)' });
  
  return (
    <div>
      <Navbar />
      <div className='CardCar'>
        <div className='CardHomeText'>
          <p className='TitleHome1'>The Road to affordable <br />Auto Insurance</p>
          <p className='SubTitleHome1'>Reasonable rates. Eco-friendly. Tailored to your driving habits.</p>
          <div className='buttonHome'>
            <Button />
          </div>
          <div className='CardimgCar'>
          <img src={isMobileOrTablet ? CarInicialMobile : CarInicial} className={isMobileOrTablet ? 'imgCar' : 'imgCar'} />
          </div>
        </div>
      </div>

      <div className='CardCar4'>
        <h1 className='TitleCar3'>Innovative car insurance</h1>
        <p className='SubTitleRenter3'>We employ location-based technology to enhance and make your insurance more cost-effective.</p>
        {isMobileOrTablet ?  <div style={{ width: '109%', gridColumn: '1/12' }}><ServiceCarMobile/></div> : <ServiceCar/>}
      </div>

      <div className='CardCar4'>
        <h1 className='TitleCar3'>What’s covered</h1>
        <p className='SubTitleRenter3'>From minor collisions to complete losses, our team is prepared to assist!</p>
        {isMobileOrTablet ? <div style={{ width: '109%', gridColumn: '1/12' }}><ServiceCarDosMobile/></div>: <div className='ServiceCar'> <ServiceCarDos/> </div>}
      </div>

      <div className='CardRenter3'>
        <h1 className='TitleCar3'>Drivers with low mileage pay reduced costs</h1>
        <p className='SubTitleCar3'>
          The FIG app enables us to determine your insurance cost according to your driving habits.
          Driving less, which results in reduced carbon emissions, leads to lower charges for you!
        </p>
        <div className='ImageCar'>
        <img src={isMobileOrTablet ? PigMobile : Pig} className={isMobileOrTablet ? 'imgCar' : 'imgCar'} />
        </div>
      </div>

      <div className='CardCar3'>
        <h1 className='TitleCar3'>Electric and hybrid vehicles enjoy lower costs</h1>
        <p className='SubTitleCar3'>
          Eco-friendly vehicles significantly decrease carbon dioxide emissions, prompting us to introduce special coverages and discounts for electric and hybrid cars.
        </p>
        {isMobileOrTablet ? <div style={{ width: '109%', gridColumn: '1/12' }}><ServiceCarTresMobile/> </div> : <div className='ServiceCar'> <ServiceCarTres/> </div>}
      </div>
      <div className='CardRenter4'>
        <h1 className='TitleCar3'>Save even more with our Car + Home bundle</h1> 
        <text className='SubTitleCar3'>Your bundle discount will be automatically applied to any mix of our products.</text>   
      {isMobileOrTablet ? 
      <>
        <div className='InfoImg'>
          <div className='containerImg'>
            <img src={CarHome} width={'100%'} height={'100%'}/>
            <h1 className='TitleCard'>CAR + HOME</h1>
          </div>
        </div>
          <div className='InfoCar'>
          <div className='containerCar'>
            <img src={CarRenter} width={'100%'} height={'100%'}/>
            <h1 className='TitleCar'>CAR + RENTERS</h1>
          </div>
          <div className='containerCar'>
            <img src={CarPet} width={'100%'} height={'100%'}/>
            <h1 className='TitleCar'>CAR + PET HEALTH</h1>
          </div>
          <div className='containerCar'>
            <img src={CarLife} width={'100%'} height={'100%'}/>
            <h1 className='TitleCar'>CAR + TERM LIFE</h1>
          </div>
          </div>
      </> : 
        <>
          <div className='InfoImg'>
          <div className='containerImg'>
            <img src={CarHome} width={'40%'} height={'40%'}/>
            <h1 className='TitleCard'>CAR + HOME</h1>
          </div>
        </div>
          <div className='InfoCard'>
            <div className='containerCard'>
              <img src={CarRenter} width={'70%'} height={'70%'}/>
              <h1 className='TitleCard'>CAR + RENTERS</h1>
            </div>
            <div className='containerCard'>
              <img src={CarPet} width={'70%'} height={'70%'}/>
              <h1 className='TitleCard'>CAR + PET HEALTH</h1>
            </div>
            <div className='containerCard'>
              <img src={CarLife} width={'70%'} height={'70%'}/>
              <h1 className='TitleCard'>CAR + TERM LIFE</h1>
            </div>
          </div>
        </>
      } 
      </div>
      <div className='CardHome2'>
        <h1 className='TitleHome4'>America’s #1 rated insurance </h1>
        <p className='SubTitleHome2'>4.9 stars in the App store, and top-rated by others</p>
        <div className={isMobileOrTablet ? 'RatingMobile' : 'Rating'}>
        {isMobileOrTablet ? <StarMobile/> : <Star/>}
        </div>
        <div className={isMobileOrTablet ? 'CarouselMobileCar' : 'Carousel'}>
        {isMobileOrTablet ? <CarouselMobile/> : <Carousel/>}
        </div>
      </div>

      <div className='CardRenter4'>
        <h1 className='TitleRenter3'>Good to know...</h1>
        <p className='SubTitleRenter4'>
          Auto insurance can seem complicated, but we've worked hard to simplify it.
          If you still have questions, you can check out car insurance FAQ.
        </p>
        <div className='BoxRender'>
        {isMobileOrTablet ? <QuestionsCarMobile/> : <QuestionsCar/>}
        </div>
      </div>
      <div className='CardRenter5'>
        <div className='buttonRenter2'>
          <Button />
        </div>
        <p className='SubTitleCar'>As low as $30/mo, based on your driving profile.</p>
        <img src={isMobileOrTablet ? CasaMobile : Casa} className={isMobileOrTablet ? 'ImageCarDos' : 'ImageCarDos'} />
      </div>
      <div>
        {isMobileOrTablet ? <FooterMobile /> : <Footer />}
      </div>
    </div>
  );
}

export default Car;
