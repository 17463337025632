import React from 'react';
import '../../App.css';
import '../Animated/animation.css'

import ComponentNueve from '../../assets/icons/car/Component 9.png';
import ComponentDiez from '../../assets/icons/car/Component 10.png';
import ComponentOnce from '../../assets/icons/car/Component 11.png';
import ComponentDoce from '../../assets/icons/car/Component 12.png';
import ComponentTrece from '../../assets/icons/car/Component 13.png'


function ServiceCar() {
  return (
    <>
    <div className='InfoCard'>
      <div className='containerCard'>
        <div className='imgBordIcon'>
          <img src={ComponentNueve}   className='imgIcon'/>
        </div>        
        <h1 className='TitleCard'>Swift resolution <br/>of claims</h1>
        <text className='TextCard6'>Providing additional information <br/>to expedite the processing  <br/> of your claim.</text>
      </div>
      <div className='containerCard'>
        <div className='imgBordIcon'>
          <img src={ComponentDiez}   className='imgIcon'/>
        </div>        
        <h1 className='TitleCard'>Fair rates tailored by  <br/>your driving habits.</h1>
        <text className='TextCard6'>Earn a reduced premium<br/> by driving better  <br/>and receiving a discount.</text>
      </div>
      <div className='containerCard'>
        <div className='imgBordIcon'>
          <img src={ComponentOnce}   className='imgIcon'/>
        </div>        
        <h1 className='TitleCard'>We'll plant trees to  <br/> mitigate your car's impact</h1>
        <text className='TextCard6'>Allow us to reduce your car's  <br/> carbon footprint through  <br/> tree planting.</text>
      </div>
    </div>
    <div className='InfoCardDos'>
      <div className='containerCardDos'>  
        <div className='imgBordIcon'>
          <img src={ComponentDoce}   className='imgIcon'/>
        </div>   
        <h1 className='TitleCard'>Emergency <br/>collision aid</h1>
        <text className='TextCard'>We'll detect accidents and <br/>assist you promptly.</text>
      </div>
      <div className='containerCardDos'>
        <div className='imgBordIcon'>
          <img src={ComponentTrece}   className='imgIcon'/>
        </div>        
        <h1 className='TitleCard'>Complimentary  <br/> roadside aid</h1>
        <text className='TextCard'>Towing, jump-starts, tire changes,  <br/> and winching - all on us!</text>
      </div>
    </div>
    </>
  )
}

export default ServiceCar
