import React from 'react'
import { useMediaQuery } from 'react-responsive';

import Navbar from '../components/Header/Navbar'
import Footer from '../components/Footer'
import Button from '../components/Button'
import FooterMobile from '../components/FooterMobile';

import Star from '../Utils/Star'
import Carousel from '../Utils/Carousel'
import ServicePet from '../Utils/Pet/ServicePet'
import ServicePetDos from '../Utils/Pet/ServicePetDos'
import QuestionsPet from '../Utils/Pet/QuestionsPet'
import StarMobile from '../Utils/StarMobile';
import CarouselMobile from '../Utils/CarouselMobile';
import ServicePetMobile from '../Utils/Pet/ServicePetMobile';
import ServicePetDosMobile from '../Utils/Pet/ServicePetDosMobile';
import QuestionsPetMobile from '../Utils/Pet/QuestionPetMobile';

import PetImage from '../assets/img/pet/PetIlustracion2_v2.png';
import PetImageMobile from '../assets/img/pet/Iluspet-mobilecatanddog.png'
import PetFooter from '../assets/gif/pet/Manos-Gatos.gif';
import PetFooterMobile from '../assets/gif/pet/Manos-Gatos-Mobile.gif';
import PetInicial from '../assets/gif/pet/Ilustracion1Pet-Mobile.gif'



function Pet() {
  const isMobileOrTablet = useMediaQuery({ query: '(max-width: 1200px)' });
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
  const isTablet = useMediaQuery({ query: '(min-width: 601px) and (max-width: 1200px)' });


  return (
      <div className='App'>
        <Navbar/>
        <scroll className='Scroll'>
          <div className='CardPet'>
              <p className='TitleHome1'>Pet insurance with <br/>lightning-fast everything</p>
              <text className='SubTitleHome1'>Join within seconds. Receive payment in minutes. Award-winning service.</text>
              <div className='buttonHome'>
                <Button/> 
              </div>
              <text className='SubTitlePet1'>Get <strong>5% off</strong> when you bundle</text>
              <img src={isMobileOrTablet ? PetInicial : ''} className={isMobileOrTablet ? 'imgPetIncial' : ''} />     
          </div>
          <div className='CardHome2'>
            <h1 className='TitlePet2'>Top-rated insurance in America,now available  <br/>for your cats and dogs. </h1>
            <text className='SubTitlePet2'>FIG has earned 4.9 stars in the App store, and is  <br/> also top-rated by others</text>
            <div className={isMobileOrTablet ? 'RatingMobileLife' : 'Rating'}>
              {isMobileOrTablet ? <StarMobile/> : <Star/>}
            </div>
            <div className={isMobileOrTablet ? 'CarouselMobileRenter' : 'Carousel'}>
              {isMobileOrTablet ? <CarouselMobile/> : <Carousel/>}
            </div>    
          </div>
          <div className='CardRenter3'>
              <h1 className='TitleRenter3'>The protection your pet truly requires</h1>
              <text className='SubTitleRenter3'>Carefully designed with input from our veterinary team and enthusiastic pet owners</text>  
              <div className={isMobileOrTablet ? 'Service':'Service'}>
              {isMobileOrTablet ? <ServicePetMobile/> : <ServicePet/>}        
              </div>
          </div>
          <div className='CardRenter3'>
            <h1 className='TitleHome5'>Significant savings on tests, vaccines, and essential care</h1> 
            <text className='SubTitlePet3'>Save money on your veterinarian expenses for annual routine care with our preventive packages</text>   
            <div className={isMobileOrTablet ? 'Service':'ServicePet'}>
              {isMobileOrTablet ? <ServicePetDosMobile/> : <ServicePetDos/>}        
            </div>
  
          </div>
          <div className='CardPet4'>
              <text className='TitlePet6'>Designed for puppies and kittens</text> 
              {isMobile ? (
                <>
              <text className='SubTitlePet5'>A preventive care package tailored for the youngest members of your furry family, covering essential procedures such as spaying or neutering, microchipping, flea medication, and up to six vaccines or boosters.</text>   
              <div className='ImagePet'>
                <img src={PetImageMobile}  width={'100%'} height={'100%'} />
              </div>
                </>
              ) : isTablet ? (
                <>
              <text className='SubTitlePet5'>A preventive care package tailored for the youngest members of your furry family, covering essential procedures such as spaying or neutering, microchipping, flea medication, and up to six vaccines or boosters.</text>   
              <div className='ImagePet'>
                <img src={PetImageMobile}  width={'80%'} height={'100%'} />
              </div>
                </>
              ) : (
                <>
              <text className='SubTitlePet5'>A preventive care package tailored for the youngest members of your furry family, covering essential procedures such as spaying or<br/> neutering, microchipping, flea medication, and up to six vaccines or boosters.</text>   
              <div className='ImagePet'>
                <img src={PetImageMobile}  width={'80%'} height={'100%'} />
              </div>
                </>
              )}                
          </div>
          <div className='CardRenter4'>
              <h1 className='TitlePet3'>Good to know...</h1>
              {isMobileOrTablet ?               
              <>
              <text className='SubTitlePet5'>Here are a few important things to note about your pet health insurance plan.<br/> If you still have questions, you can check out pet insurance explained.</text>  
              <div className='BoxRender'>
                <QuestionsPetMobile/>
              </div>
              </>
               :
              <>
                <text className='SubTitlePet5'>Here are a few important things to note about your pet health insurance plan.<br/> If you still have questions, you can check out pet insurance explained.</text>  
                <div className='BoxRender'>
                 <QuestionsPet/>
              </div>
              </>    
              } 
          </div>
          <div className='CardPet6'>
            <p className='TitlePet6'>Amazing prices. Even better value.</p> 
            <img src={isMobileOrTablet ? PetFooterMobile : PetFooter} className={isMobileOrTablet ? 'ImageFooterPet' : 'ImageFooterPet'} />      
          </div>
        </scroll>
        {isMobileOrTablet ? <FooterMobile /> : <Footer />} 
      </div>
)}


export default Pet

