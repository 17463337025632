import React from 'react'
import Descriptionhom from './Descriptionhom.js'
import Renter from '../../assets/gif/home/APTO.gif';
import Car from "../../assets/gif/home/CARRO.gif";
import TermLife from "../../assets/gif/home/CUADRO.gif";
import Pet from "../../assets/gif/home/MASCOTAS.gif"

function BoxHom() {
  const items =[
    {"Image":Renter,"Servicie":"Renters","Description":"Protection for your things, in and out your home","Price":"FROM $5/MO","Link":"/renters"},
    {"Image":Car,"Servicie":"Car","Description":"Safeguard your vehicle, passengers.","Price":"FROM $30/MO","Link":"/car"},
    {"Image":TermLife,"Servicie":"Term life","Description":"Protecting the people you love","Price":"FROM $9/MO","Link":"/life"},
    {"Image":Pet,"Servicie":"Pet","Description":"Coverage for the health of your beloved furry family members.","Price":"FROM $9/MO","Link":"/pet"}]

  return (
    <div>
      {items.map((item) => (
          <Descriptionhom key={item} item={item} />
        ))}
    </div>
)}


export default BoxHom
